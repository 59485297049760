import { AdminPanelType } from '@root-gipro/modules/AdminPanel/interfaces/admin-panel.actions'
import { CompanyIPR, IUser } from '@root-gipro/modules/AdminPanel/interfaces/user'
import {
	deleteSolutionLocal,
	getCompanyGroupsFetch,
	getUsersFetch,
	loadingUsers,
	refreshOneSolutionAction,
	setCompanies,
	setCreateLoad,
	setIsDuplicateState,
	setLoadingId,
	setLoadingSolutionsPage,
	setLoadSolutions,
	setSolutionById,
	setSolutions,
	setUser,
	setUsers,
} from '@root-gipro/modules/AdminPanel/store/actions'
import { getUserAuthRole, getUserRole } from '@root-gipro/modules/AdminPanel/store/helpers'
import { showNotify } from '@root-gipro/modules/notify/store/actions'
import { setLoadingStates, setProgressPrecent } from '@root-gipro/modules/userProjects/store/actions'
import store from '@root-gipro/store'
import { setCompanyList } from '@root-gipro/store/actions'
import { fetchAuthHeaders, fetchData, fetchHeaders } from '@root-gipro/store/api'
import { authorizeApi } from '@root-gipro/store/api/index'
import { ICompany, IState } from '@root-gipro/store/interfaces'
import { call, cancel, delay, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

function* getAuthToken() {
	try {
		const authorize: any = yield call(authorizeApi, 'auth')
		if (authorize && authorize.status === 'success') {
			yield localStorage.setItem('service_auth_token', authorize.access_token)
			yield put(getUsersFetch())
		}
	} catch (error) {
		console.log(error)
	}
}

function* getUsers() {
	try {
		yield put(loadingUsers(true))
		let users: any = []

		yield fetch(`https://${process.env.REACT_APP_ENV_AUTH}/api/v1/users`, {
			method: 'GET',
			headers: fetchAuthHeaders(),
		})
			.then(res => res.json())
			.then(data => data.users)
			.then(data => {
				users = data
			})

		yield put(setUsers(users))
		yield put(loadingUsers(false))
	} catch (error) {
		console.log(error)
		yield put(loadingUsers(false))
	}
}

function* setUserRoleFetch({
	userId,
	role,
}: {
	type: typeof AdminPanelType.SET_USER_ROLE_FETCH
	userId: number
	role: number
}) {
	try {
		const userRole: any = yield getUserRole(userId)
		const userRoleAuth = yield getUserAuthRole(userId)

		const switchRole = async () => {
			const req = await fetch(`https://${process.env.REACT_APP_ENV_AUTH}/api/v1/userRoles/${userRole}`, {
				method: 'PATCH',
				headers: fetchAuthHeaders(),
				body: JSON.stringify({ roleId: role }),
			})
			const data = await req.json()
			return data
		}
		const switchRoleAuth = async () => {
			const req = await fetch(`https://${process.env.REACT_APP_ENV_AUTH}/api/v1/userRoles/${userRoleAuth}`, {
				method: 'PATCH',
				headers: fetchAuthHeaders(),
				body: JSON.stringify({ roleId: role }),
			})
			const data = await req.json()
			return data
		}

		yield call(switchRole)
		yield call(switchRoleAuth)
		yield put(getUsersFetch())
	} catch (error) {
		console.log(error)
	}
}
function getErrorMessages(errors: any) {
	const messages: [] = []

	function extractMessages(errorObject: []) {
		for (const key in errorObject) {
			if (typeof errorObject[key] === 'object') {
				extractMessages(errorObject[key])
			} else {
				messages.push(errorObject[key])
			}
		}
	}

	extractMessages(errors)
	return messages
}

function* createUser({ user }: { type: typeof AdminPanelType.CREATE_USER; user: IUser }) {
	try {
		const reqApi = async () => {
			const res = await fetch(`https://${process.env.REACT_APP_ENV_AUTH}/api/v1/users`, {
				method: 'POST',
				headers: fetchAuthHeaders(),
				body: JSON.stringify(user),
			})
			if (res.status === 200 && res.ok) {
				const data = await res.json()
				return data
			} else {
				const data = await res.json()
				if (data.errors) {
					const errorMessages = getErrorMessages(data.errors)
					store.dispatch(
						showNotify({
							type: 'error',
							message: errorMessages.join(', '),
						})
					)
					throw Error(errorMessages.join(', '))
				}
			}
		}
		yield call(reqApi)
		yield put(getUsersFetch())
	} catch (error) {
		console.error(error)
	}
}

function* checkUserInfoFetch({ id }: { type: typeof AdminPanelType.CHECK_USER_INFO; id: number }) {
	try {
		const getUser = async () => {
			const req = await fetch(`https://${process.env.REACT_APP_ENV_AUTH}/api/v1/users/${id}`, {
				method: 'GET',
				headers: fetchAuthHeaders(),
			})
			const data = await req.json()
			return data.user
		}

		const user = yield call(getUser)
		yield put(setUser(user))
	} catch (error) {
		console.log(error)
	}
}

function* updateUserInfo({
	id,
	user,
	date_start,
	date_end,
	access,
	idUserAccess,
}: {
	type: typeof AdminPanelType.UPDATE_USER_INFO_FETCH
	id: number
	user: IUser
	date_start: number | null
	date_end: number | null
	access: boolean
	idUserAccess?: number | null
}) {
	const { date_start: start_date, date_end: end_date, access: access_user, ...clearUser } = user
	const userChangeInfo = {
		date_start: date_start,
		date_end: date_end,
		access: access,
		...clearUser,
	}
	try {
		const updateUser = async () => {
			const req = await fetch(`https://${process.env.REACT_APP_ENV_AUTH}/api/v1/users/${id}`, {
				method: 'PATCH',
				headers: fetchAuthHeaders(),
				body: JSON.stringify(userChangeInfo),
			})
			if (req.status === 200 && req.ok) {
				const data = await req.json()
				return data.user
			} else {
				const data = await req.json()
				if (data.errors) {
					const errorMessages = getErrorMessages(data.errors)
					store.dispatch(
						showNotify({
							type: 'error',
							message: errorMessages.join(', '),
						})
					)
					throw Error(errorMessages.join(', '))
				}
			}
		}
		yield call(updateUser)
		yield put(getUsersFetch())
	} catch (error) {
		console.error(error)
	}
}

function* updateUserIpr({
	id,
	user,
	iprIds,
}: {
	type: typeof AdminPanelType.UPDATE_USER_INFO_FETCH
	id: number
	user: IUser
	iprIds: CompanyIPR[]
}) {
	const { verifiedEmail, deviceLimit, roleId, id: userId, company_ipr, ...clearUser } = user
	const userChangeInfo = {
		company_ipr: iprIds,
		password: null,
		date_start: user.access?.date_start,
		date_end: user.access?.date_end,
		...clearUser,
	}
	try {
		const updateUser = async () => {
			const req = await fetch(`https://${process.env.REACT_APP_ENV_AUTH}/api/v1/users/${id}`, {
				method: 'PATCH',
				headers: fetchAuthHeaders(),
				body: JSON.stringify(userChangeInfo),
			})
			const data = await req.json()
			return data.user
		}
		yield call(updateUser)
		yield put(getUsersFetch())
	} catch (error) {
		console.error(error)
	}
}

function* deleteUser({ id }: { type: typeof AdminPanelType.DELETE_USER; id: number }) {
	try {
		const deleteUserFetch = async () => {
			const req = await fetch(`https://${process.env.REACT_APP_ENV_AUTH}/api/v1/users/${id}`, {
				method: 'DELETE',
				headers: fetchAuthHeaders(),
			})
			if (req.status === 200 && req.ok) {
				const data = await req.json()
				return data.user
			} else {
				const data = await req.json()
				if (data.errors) {
					const errorMessages = getErrorMessages(data.errors)
					store.dispatch(
						showNotify({
							type: 'error',
							message: errorMessages.join(', '),
						})
					)
					throw Error(errorMessages.join(', '))
				}
			}
		}

		yield call(deleteUserFetch)
		yield put(getUsersFetch())
		yield put(
			showNotify({
				type: 'success',
				message: `Пользователь успешно удален`,
			})
		)
	} catch (error) {
		console.error(error)
	}
}

function* fetchCompanyGroupsInfo() {
	try {
		const company: ICompany[] = yield call(fetchData, '/company', (res: any) => res.company)
		yield put(setCompanies(company))
		yield put(setCompanyList(company))
	} catch (error) {
		console.log(error)
	}
}

function* createCompany({ company }: { type: typeof AdminPanelType.CREATE_COMPANY; company: any }) {
	try {
		const reqApi = async () => {
			const { id, ...compObj } = company

			const comp = await fetch(`https://${process.env.REACT_APP_ENV}/ptk/v2/company`, {
				method: 'POST',
				headers: fetchHeaders(),
				body: JSON.stringify(compObj),
			})

			const dataComp = await comp.json()
			return dataComp
		}
		yield call(reqApi)
		yield put(getCompanyGroupsFetch())
	} catch (error) {
		console.error(error)
		yield put(
			showNotify({
				type: 'error',
				message: `${error}`,
			})
		)
	}
}

function* updateCompanyIpr({ ipr }: { type: typeof AdminPanelType.UPDATE_COMPANY_IPR; ipr: any }) {
	try {
		const { id, ...iprObj } = ipr
		const updateCompanyIpr = async () => {
			const req = await fetch(`https://${process.env.REACT_APP_ENV}/ptk/v2/ipr/${id}`, {
				method: 'PATCH',
				headers: fetchHeaders(),
				body: JSON.stringify({ ...iprObj, is_active: iprObj.is_active == 1 ? true : false }),
			})
			const data = await req.json()
			return data
		}
		yield call(updateCompanyIpr)
		yield put(getCompanyGroupsFetch())
	} catch (error) {
		console.error(error)
		yield put(
			showNotify({
				type: 'error',
				message: `${error}`,
			})
		)
	}
}
function* createCompanyIpr({ ipr }: { type: typeof AdminPanelType.CREATE_COMPANY_IPR; ipr: any }) {
	try {
		const reqApi = async () => {
			const comp = await fetch(`https://${process.env.REACT_APP_ENV}/ptk/v2/ipr`, {
				method: 'POST',
				headers: fetchHeaders(),
				body: JSON.stringify(ipr),
			})

			const dataComp = await comp.json()
			return dataComp
		}
		yield call(reqApi)
		yield put(getCompanyGroupsFetch())
	} catch (error) {
		console.error(error)
		yield put(
			showNotify({
				type: 'error',
				message: `${error}`,
			})
		)
	}
}

function* createCopyVersionIpr({ id }: { type: typeof AdminPanelType.CREATE_COPY_VERSION_IPR; id: any }) {
	try {
		// Функция для запроса
		const reqApi = () =>
			fetch(`https://${process.env.REACT_APP_ENV}/ptk/v2/ipr?fromIprId=${id}`, {
				method: 'POST',
				headers: fetchHeaders(),
			})

		const response = yield call(reqApi)

		if (!response.ok) {
			yield put(
				showNotify({
					type: 'error',
					message: 'Ошибка копирования версии, попробуйте позже.',
				})
			)
			return
		}

		// Потоковое чтение данных
		const reader = response.body?.getReader()
		const decoder = new TextDecoder('utf-8')
		let buffer = ''
		let finalJsonBuffer = ''

		while (true) {
			const { done, value } = yield call([reader, 'read'])

			buffer += decoder.decode(value || new Uint8Array(), { stream: true })
			let boundary = buffer.indexOf('\n')

			while (boundary !== -1) {
				const chunk = buffer.slice(0, boundary).trim()
				buffer = buffer.slice(boundary + 1)

				if (chunk.startsWith('data:')) {
					const progressPercent = parseInt(chunk.replace('data:', '').trim())
					if (!isNaN(progressPercent)) {
						yield put(setProgressPrecent(progressPercent)) // Обновляем прогресс
					}
				} else {
					finalJsonBuffer = chunk
				}

				boundary = buffer.indexOf('\n')
			}

			// Если поток завершён
			if (done) {
				if (buffer.trim()) {
					finalJsonBuffer = buffer.trim()
				}
				if (finalJsonBuffer) {
					const parsedData = JSON.parse(finalJsonBuffer)
					if (parsedData.status === 'failure') {
						yield put(
							showNotify({
								type: 'error',
								message: `Ошибка: ${parsedData.message}`,
							})
						)
					} else {
						yield put(getCompanyGroupsFetch()) // Успешное завершение
						yield put(
							showNotify({
								type: 'success',
								message: `Копирование завершено`,
							})
						)
					}
				} else {
					yield put(
						showNotify({
							type: 'error',
							message: 'Ошибка копирования версии, попробуйте позже.',
						})
					)
				}
				break
			}
		}

		yield put(setProgressPrecent(0)) // Сброс прогресса
	} catch (error) {
		console.error(error)
		yield put(
			showNotify({
				type: 'error',
				message: `${error}`,
			})
		)
	} finally {
		yield put(setLoadingStates('', false))
	}
}

function* deleteCompanyIpr({ id }: { type: typeof AdminPanelType.DELETE_COMPANY_IPR; id: number }) {
	try {
		const reqApi = async () => {
			const req = await fetch(`https://${process.env.REACT_APP_ENV}/ptk/v2/ipr/${id}`, {
				method: 'DELETE',
				headers: fetchHeaders(),
			})
			const data = await req.json()
			return data
		}

		const res = yield call(reqApi)
		if (res.status == 'success') {
			yield put(getCompanyGroupsFetch())
			yield put(
				showNotify({
					type: 'success',
					message: `Версия ИПР успешно удалена`,
				})
			)
		} else {
			throw Error(res.message)
		}
	} catch (error) {
		console.log(error)
		yield put(
			showNotify({
				type: 'error',
				message: `${error.message}`,
			})
		)
	}
}

function* updateCompanyInfo({
	company,
}: {
	type: typeof AdminPanelType.UPDATE_COMPANY
	id: number | string
	company: any
}) {
	try {
		const { id, ...compObj } = company
		const updateCompany = async () => {
			const req = await fetch(`https://${process.env.REACT_APP_ENV}/ptk/v2/company/${id}`, {
				method: 'PATCH',
				headers: fetchHeaders(),
				body: JSON.stringify(compObj),
			})
			const data = await req.json()
			return data
		}
		yield call(updateCompany)
		yield put(getCompanyGroupsFetch())
	} catch (error) {
		console.error(error)
		yield put(
			showNotify({
				type: 'error',
				message: `${error}`,
			})
		)
	}
}

function* getSolutions({ type }: { type: AdminPanelType.SET_SOLUTIONS_PARAMS }) {
	try {
		const state: IState = yield select()
		const solutionsTechParams = state.adminPanel.solutionsTechParams
		const { page = 0, order = '', techParamName = '', is_duplicate = 0, companyId } = solutionsTechParams
		const offset = page * 50

		yield put(page === 0 ? setLoadingSolutionsPage(true) : setLoadSolutions(true))

		const queryParams = new URLSearchParams({
			companyId: companyId, //компания юзера по умолчанию должны быть
			limit: '50',
			with: 'uncCell',
			offset: offset.toString(),
		})

		if (techParamName) queryParams.set('search', techParamName)
		if (order) queryParams.set('order', order)
		if (is_duplicate) queryParams.set('is_duplicate', String(is_duplicate))

		const url = `/unc-tech-solutions?${queryParams.toString()}`

		const response: any = yield call(fetchData, url, (res: any) => res)

		const solutions = response.uncTechSolutions || []
		const resultsCnt = response.resultsCnt || 0

		yield put(setSolutions(solutions, resultsCnt, order, techParamName, page))
		yield put(setIsDuplicateState(response.duplicates))
	} catch (error) {
		console.error('Ошибка при загрузке решений:', error)
		yield put(
			showNotify({
				type: 'error',
				message: `Ошибка при загрузке решений: ${error}`,
			})
		)
	} finally {
		yield put(setLoadSolutions(false))
		yield put(setLoadingSolutionsPage(false))
	}
}

function* createSolution({ solution }: { type: typeof AdminPanelType.CREATE_SOLUTION; solution: any }) {
	try {
		yield put(setCreateLoad(true))
		const url = `/unc-tech-solutions?with=uncCell`
		const response: any = yield call(fetchData, url, (res: any) => res, 'POST', JSON.stringify(solution))

		if (response.status === 'success') {
			// для локального добавления без запроса, если ид с бэка приходит корректно
			// yield put(createSolutionLocal(response.uncTechSolution))
			yield put({ type: AdminPanelType.SET_SOLUTIONS_PARAMS })
			yield put(
				showNotify({
					type: 'success',
					message: `Добавлено новое тех. решение: ${solution.techParamName}`,
				})
			)
		}
	} catch (error) {
		console.error(error)
		yield put(
			showNotify({
				type: 'error',
				message: `Ошибка при создании тех. решения: ${error}`,
			})
		)
	} finally {
		yield put(setCreateLoad(false))
	}
}

function* updateSolution({
	solution,
	companyId,
}: {
	type: typeof AdminPanelType.EDIT_SOLUTION
	solution: any
	companyId: any
}) {
	try {
		yield put(setLoadingId({ editLoadingId: solution.id, deleteLoadId: null }))
		const url = `/unc-tech-solutions/${solution.id}?with=uncCell`
		const response: any = yield call(
			fetchData,
			url,
			(res: any) => res,
			'PATCH',
			JSON.stringify({
				uncCellId: solution.uncCellId,
				techParamName: solution.techParamName,
				companyId: companyId,
			})
		)

		if (response.status === 'success') {
			const updatedProject = response.project
			// yield put(editSolutionLocal(updatedProject))
			// решили на фронте получать обновленные данные об изменениях
			yield put(refreshOneSolutionAction(updatedProject.id))

			yield put(
				showNotify({
					type: 'success',
					message: `Тех. решение обновлено: ${solution.techParamName}`,
				})
			)
		} else {
			throw new Error(response.message || 'Ошибка обновления')
		}
	} catch (error) {
		console.error(error)
		yield put(
			showNotify({
				type: 'error',
				message: `Ошибка при обновлении тех. решения: ${error}`,
			})
		)
	} finally {
		yield put(setLoadingId({ editLoadingId: null, deleteLoadId: null }))
	}
}

function* refreshOneSolution({ type, id }: { type: AdminPanelType.REFRESH_SOLUTIONS; id: number }) {
	try {
		const state: IState = yield select()
		const solutionsTechParams = state.adminPanel.solutionsTechParams
		const { page = 0, order = '', techParamName = '', is_duplicate = 0, companyId } = solutionsTechParams
		const offset = page * 50

		if (!companyId) {
			yield put(showNotify({ type: 'error', message: 'Не найден companyId' }))
			return
		}

		const queryParams = new URLSearchParams({
			companyId: companyId, //компания юзера по умолчанию должны быть
			limit: '50',
			with: 'uncCell',
			offset: offset.toString(),
		})

		if (techParamName) queryParams.set('search', techParamName)
		if (order) queryParams.set('order', order)
		if (is_duplicate) queryParams.set('is_duplicate', String(is_duplicate))

		const url = `/unc-tech-solutions?${queryParams.toString()}`

		const response: any = yield call(fetchData, url, (res: any) => res)
		const solutions = response.uncTechSolutions || []

		const updatedSolution = solutions.find((sol: any) => sol.id === id)
		yield put(setSolutionById(updatedSolution))
	} catch (error) {
		console.error('Ошибка при загрузке решений:', error)
		yield put(
			showNotify({
				type: 'error',
				message: `Ошибка при загрузке решений: ${error}`,
			})
		)
	} finally {
		yield put(setLoadSolutions(false))
		yield put(setLoadingSolutionsPage(false))
	}
}

function* deleteSolutionSaga({ id }: { type: typeof AdminPanelType.DELETE_SOLUTION; id: number }) {
	try {
		yield put(setLoadingId({ editLoadingId: null, deleteLoadId: id }))
		const url = `/unc-tech-solutions/${id}`
		const response: any = yield call(fetchData, url, (res: any) => res, 'DELETE')

		if (response.status === 'success') {
			yield put(deleteSolutionLocal(id))

			yield put(
				showNotify({
					type: 'success',
					message: `Тех. решение удалено`,
				})
			)
		} else {
			throw new Error(response.message || 'Ошибка удаления')
		}
	} catch (error) {
		console.error(error)
		yield put(
			showNotify({
				type: 'error',
				message: `Ошибка при удалении тех. решения: ${error}`,
			})
		)
	} finally {
		yield put(setLoadingId({ editLoadingId: null, deleteLoadId: null }))
	}
}

function* updateProgressDuringRequest(expectedTime: number) {
	let progress = 0
	const timeStepFast = expectedTime / 80
	const timeStepSlow = (expectedTime / 80) * 2

	try {
		while (progress < 70) {
			yield delay(timeStepFast * 1000)
			progress += 1
			yield put(setProgressPrecent(progress))
		}

		while (progress < 95) {
			yield delay(timeStepSlow * 1000)
			progress += 1
			yield put(setProgressPrecent(progress))
		}

		yield delay(Infinity)
	} catch {
		yield put(setProgressPrecent(100))
	}
}

function* downloadTechSolutions() {
	try {
		const state: IState = yield select()
		const { order, techParamName } = state.adminPanel.solutionsTechParams

		const params = new URLSearchParams()
		params.set('with', 'uncCell')
		if (order) params.set('order', order)
		if (techParamName) params.set('techParamName', techParamName)

		const url = `https://${
			process.env.REACT_APP_ENV
		}/ptk/v2/unc-tech-solutions/forma_techSolutions?${params.toString()}`

		// Запускаем прогресс-бар загрузки
		const avg_time_per_request = 0.05
		const progressTask = yield fork(updateProgressDuringRequest, avg_time_per_request * 100)

		const response = yield call(() =>
			fetch(url, { method: 'GET', headers: fetchHeaders() })
				.then(res => res.blob())
				.then(blob => {
					const url = URL.createObjectURL(blob)
					const link = document.createElement('a')
					document.body.appendChild(link)
					link.href = url
					link.download = `Тех_решения_${new Date().toISOString().slice(0, 10)}.xlsx`
					link.click()
					link.remove()
				})
		)

		// Завершаем прогресс-бар
		yield cancel(progressTask)
		yield put(setProgressPrecent(100))
	} catch (error) {
		console.error('Ошибка при скачивании тех. решений:', error)
	} finally {
		yield put(setProgressPrecent(0))
		yield put(setLoadingStates('', false))
	}
}

export default function* adminPanel() {
	yield takeEvery(AdminPanelType.GET_AUTH_USER_TOKEN, getAuthToken)
	yield takeEvery(AdminPanelType.GET_USERS_FETCH, getUsers)
	yield takeEvery(AdminPanelType.CHECK_USER_INFO, checkUserInfoFetch)
	yield takeEvery(AdminPanelType.UPDATE_USER_INFO_FETCH, updateUserInfo)
	yield takeEvery(AdminPanelType.UPDATE_USER_IPRS, updateUserIpr)
	yield takeEvery(AdminPanelType.DELETE_USER, deleteUser)
	// yield takeEvery(AdminPanelType.GET_USER_ROLE_FETCH, getUserRole)
	yield takeEvery(AdminPanelType.CREATE_USER, createUser)
	yield takeEvery(AdminPanelType.SET_USER_ROLE_FETCH, setUserRoleFetch)
	yield takeEvery(AdminPanelType.UPDATE_COMPANY, updateCompanyInfo)
	yield takeEvery(AdminPanelType.CREATE_COMPANY, createCompany)
	yield takeEvery(AdminPanelType.GET_COMPANY_FETCH, fetchCompanyGroupsInfo)
	yield takeEvery(AdminPanelType.CREATE_COMPANY_IPR, createCompanyIpr)
	yield takeEvery(AdminPanelType.DELETE_COMPANY_IPR, deleteCompanyIpr)
	yield takeEvery(AdminPanelType.UPDATE_COMPANY_IPR, updateCompanyIpr)
	yield takeEvery(AdminPanelType.CREATE_COPY_VERSION_IPR, createCopyVersionIpr)
	// yield takeEvery(AdminPanelType.GET_SOLUTIONS, getSolutions)
	yield takeLatest(AdminPanelType.SET_SOLUTIONS_PARAMS, getSolutions)
	yield takeLatest(AdminPanelType.REFRESH_SOLUTIONS, refreshOneSolution)
	yield takeEvery(AdminPanelType.CREATE_SOLUTION, createSolution)
	yield takeEvery(AdminPanelType.EDIT_SOLUTION, updateSolution)
	yield takeEvery(AdminPanelType.DELETE_SOLUTION, deleteSolutionSaga)
	yield takeLatest(AdminPanelType.DOWNLOAD_SOLUTIONS, downloadTechSolutions)
}
