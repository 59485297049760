import { createStyles, Fade, IconButton, makeStyles, Modal, TextField, Theme, Tooltip } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import LinkIcon from '@material-ui/icons/Link'
import {
	createSolution,
	editSolution,
	setAdminUncsModalState,
	setSolutionObj,
} from '@root-gipro/modules/AdminPanel/store/actions'
import { disabledAddTechParam } from '@root-gipro/modules/AdminPanel/store/helpers'
import { PrimaryBtn } from '@root-gipro/shared/Button/Button'
import { IState, IUncCell } from '@root-gipro/store/interfaces'
import React, { FC, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			overflow: 'auto',
		},
	})
)

interface IAdminCreateSolution {
	open: boolean
	handleClose: () => void
	solutionId?: any
}

const AdminCreateSolution: FC<IAdminCreateSolution> = ({ open, handleClose, solutionId }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const companyId = Number(localStorage.getItem('companyId'))

	const { solutionObj } = useSelector((state: IState) => state.adminPanel)
	const { uncCells } = useSelector((state: IState) => state.userProjects)

	const [errorData, setErrorData] = React.useState({
		techParamName: { error: false, message: '' },
	})

	useEffect(() => {
		setErrorData({
			techParamName: { error: false, message: '' },
		})
		dispatch(setSolutionObj({})) // Очищаем при открытии
	}, [open, dispatch])

	const uncData = useMemo(() => {
		return uncCells?.find((unc: IUncCell) => unc.id === solutionObj?.data?.uncCellId)
	}, [solutionObj?.data?.uncCellId, uncCells])

	const addSolution = () => {
		if (solutionId) {
			dispatch(
				editSolution(
					{
						id: solutionObj?.data?.id,
						techParamName: solutionObj?.data?.techParamName,
						uncCellId: solutionObj?.data?.uncCellId,
					},
					companyId
				)
			)
		} else {
			if (solutionObj.data.techParamName && solutionObj.data.uncCellId) {
				dispatch(
					createSolution({
						companyId: companyId,
						uncCellId: solutionObj.data.uncCellId,
						techParamName: solutionObj.data.techParamName,
					})
				)
			}
		}
		handleClose()
		dispatch(setSolutionObj(null))
	}

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setSolutionObj({ techParamName: event.target.value }))
	}

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby='simple-modal-title'
			aria-describedby='simple-modal-description'
			className={classes.modal}
			closeAfterTransition
		>
			<Fade in={open}>
				<form
					noValidate
					autoComplete='off'
					style={{ background: 'white', padding: '32px', maxWidth: 550, width: '100%', outline: 'none' }}
				>
					{/* Техническое решение */}
					<div>
						<label style={{ marginBottom: 8, display: 'block' }}>Техническое решение</label>
						<TextField
							id='techParamName'
							value={solutionObj?.data?.techParamName || ''}
							placeholder='Техническое решение'
							variant='outlined'
							onChange={handleChange}
							error={errorData.techParamName.error}
							helperText={errorData.techParamName.message}
							fullWidth
						/>
					</div>

					{/* УНЦ */}
					<div style={{ marginTop: 16, display: 'flex', alignItems: 'center' }}>
						<Tooltip title='Выбрать УНЦ'>
							<IconButton
								type='button'
								onClick={() => dispatch(setAdminUncsModalState({ linkOpen: false, editOpen: true }))}
							>
								<LinkIcon />
							</IconButton>
						</Tooltip>

						<span style={{ marginLeft: 8 }}>{uncData?.code || 'Выберите УНЦ'}</span>

						{uncData && (
							<Tooltip title='Очистить УНЦ'>
								<IconButton
									type='button'
									onClick={() => {
										dispatch(setSolutionObj({ uncCellId: undefined }))
									}}
								>
									<ClearIcon />
								</IconButton>
							</Tooltip>
						)}
					</div>

					<PrimaryBtn
						onClick={addSolution}
						style={{ width: '100%', marginTop: 16 }}
						disabled={disabledAddTechParam(solutionObj.data, errorData, 'createSolution')}
					>
						{solutionId ? 'Сохранить' : 'Добавить'}
					</PrimaryBtn>
				</form>
			</Fade>
		</Modal>
	)
}

export default AdminCreateSolution
