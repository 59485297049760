import React, { useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Collapse } from '@material-ui/core'
import { IUncTableElem } from '@root-gipro/store/interfaces'
import { selectTableElem, fetchUncTableData } from '@root-gipro/modules/userProjects/store/actions'
import { IState } from '@root-gipro/store/interfaces/state'
import { IPointElemProps } from '@root-gipro/modules/userProjects/models/interfaces/project-unc-add.props'

export const PointElem: React.FC<IPointElemProps> = ({ name, uncMainIds, num }) => {
	const [open, setOpen] = useState<boolean>(false)
	const { uncMains } = useSelector((state: IState) => state.public)
	const { unc_sort } = useSelector((state: IState) => state.userProjects.projectObj)
	const dispatch = useDispatch()
	const filteredUnc = useMemo(() => {
		return uncMains.filter((elem: { id: number }) => uncMainIds.includes(elem.id))
	}, [uncMains, uncMainIds])

	return (
		<div className={open ? 'collapsed point_elem' : 'point_elem'}>
			<div onClick={() => setOpen(!open)} className='point_name'>
				<span className='table_num'>{num}. </span>
				{name}
			</div>
			<Collapse in={open} timeout='auto' unmountOnExit>
				{filteredUnc.map((unc: IUncTableElem) => {
					return (
						<div
							onClick={() => {
								dispatch(selectTableElem(unc))

								unc_sort && dispatch(fetchUncTableData(unc.id, unc_sort))
								dispatch(fetchUncTableData(unc.id, '2024'))
							}}
							key={unc.id}
							className='point'
						>
							<span className='table_num'>{unc.code}.</span>
							{unc.name}
						</div>
					)
				})}
			</Collapse>
		</div>
	)
}
