import React, { FC, useRef, useCallback, useContext, useState } from 'react'
import {
	Paper,
	makeStyles,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	createStyles,
} from '@material-ui/core'
import { SwapVertOutlined } from '@material-ui/icons'
import AdminSolutionsTableRow from '../AdminSolutionsTableRow/AdminSolutionsTableRow'
import Loader from '@root-gipro/shared/Loader/Loader'
import ModalDeleteSolution from '../ModalDeleteSolution/ModalDeleteSolution'
import { ModalContext } from '@root-gipro/context/modal-context'
import { deleteSolution } from '@root-gipro/modules/AdminPanel/store/actions'
import { useDispatch } from 'react-redux'

interface IAdminSolutionsTable {
	solution: any[]
	loadMore: () => void
	loadSolutions: boolean
	loadingSolutionsPage: boolean
	onChangeSort: (mode: any) => void
	sort: any
}

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			'& .MuiTableCell-root': {
				padding: '6px 12px',
			},
		},
		sortIcon: {
			width: '13px',
			verticalAlign: 'bottom',
			marginLeft: '5px',
			transition: 'transform 0.2s ease, color 0.2s ease',
		},
		sortIconActive: {
			color: '#1976d2', // Подсветка активной сортировки
		},
		sortIconDesc: {
			transform: 'rotate(180deg)', // Переворачиваем, если сортировка по убыванию
		},
		textWithIcon: {
			display: 'inline-flex',
			alignItems: 'center',
			cursor: 'pointer',
			'&:hover $sortIcon': {
				transform: 'scale(1.2)',
				color: '#1976d2',
			},
		},
	})
)

const AdminSolutionsTable: FC<IAdminSolutionsTable> = ({
	solution,
	loadMore,
	loadSolutions,
	loadingSolutionsPage,
	onChangeSort,
	sort,
}) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const { setAnchorEl, anchorEl } = useContext(ModalContext)
	const [solutionInfo, setSolutionInfo] = useState<any>()
	// Функция переключения сортировки
	const toggleSort = (field: 'techParamCode' | 'techParamName' | 'uncCellId') => {
		const newSort = sort === field ? `${field} DESC` : field
		onChangeSort(newSort)
	}

	// Функция для определения активного класса сортировки
	const getSortIconClass = (field: string) => {
		if (sort.startsWith(field)) {
			return `${classes.sortIcon} ${classes.sortIconActive} ${sort.includes('DESC') ? classes.sortIconDesc : ''}`
		}
		return classes.sortIcon
	}

	const observer = useRef<IntersectionObserver | null>(null)
	const lastElementRef = useCallback(
		(node: HTMLTableRowElement | null) => {
			if (observer.current) observer.current.disconnect()

			observer.current = new IntersectionObserver(entries => {
				if (entries[0].isIntersecting) {
					loadMore()
				}
			})

			if (node) observer.current.observe(node)
		},
		[loadMore]
	)

	return (
		<TableContainer component={Paper} className={classes.root}>
			<div style={{ width: '100%', overflowX: 'auto', height: 'auto' }}>
				<Table size='small' stickyHeader aria-label='admin panel table'>
					<TableHead>
						<TableRow>
							<TableCell style={{ width: '10%' }} align='center'>
								Действия
							</TableCell>
							<TableCell align='center' style={{ width: '15%' }} className={classes.root}>
								<span className={classes.textWithIcon} onClick={() => toggleSort('techParamCode')}>
									Шифр <SwapVertOutlined className={getSortIconClass('techParamCode')} />
								</span>
							</TableCell>
							<TableCell style={{ width: '40%' }}>
								<span className={classes.textWithIcon} onClick={() => toggleSort('techParamName')}>
									Техническое решение <SwapVertOutlined className={getSortIconClass('techParamName')} />
								</span>
							</TableCell>
							<TableCell style={{ width: '20%' }} align='center'>
								Единица измерения
							</TableCell>
							<TableCell style={{ width: '15%' }} align='center'>
								<span className={classes.textWithIcon} onClick={() => toggleSort('uncCellId')}>
									УНЦ <SwapVertOutlined className={getSortIconClass('uncCellId')} />
								</span>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{loadingSolutionsPage ? (
							<tr>
								<td colSpan={100} style={{ textAlign: 'center', padding: '10px' }}>
									<Loader />
								</td>
							</tr>
						) : solution.length > 0 ? (
							solution.map((item, index) => {
								const isLastItem = index === solution.length - 1
								return (
									<AdminSolutionsTableRow
										ref={isLastItem ? lastElementRef : null}
										solution={item}
										key={item.id}
										setSolutionInfo={setSolutionInfo}
									/>
								)
							})
						) : (
							<tr>
								<td colSpan={100} style={{ textAlign: 'center', padding: '10px' }}>
									Нет данных для отображения
								</td>
							</tr>
						)}

						{loadSolutions && !loadingSolutionsPage && (
							<tr>
								<td colSpan={100} style={{ textAlign: 'center', padding: '10px' }}>
									<Loader />
								</td>
							</tr>
						)}
					</TableBody>
				</Table>
			</div>
			{anchorEl?.dataset.popup === 'delete_solution' && (
				<ModalDeleteSolution
					anchorEl={anchorEl}
					solution={solutionInfo}
					setAnchorEl={setAnchorEl!}
					handleDelete={() => dispatch(deleteSolution(solutionInfo?.id))}
				/>
			)}
		</TableContainer>
	)
}

export default AdminSolutionsTable
