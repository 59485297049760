import { IUser } from '@root-gipro/modules/AdminPanel/interfaces/user'
import { ICompany, IOpenModalCreateCompany } from '@root-gipro/store/interfaces'

export enum AdminPanelType {
	GET_AUTH_USER_TOKEN = 'GET_AUTH_USER_TOKEN',
	GET_USERS_FETCH = 'GET_USERS_FETCH',
	GET_USER_ROLE_FETCH = 'GET_USER_ROLE_FETCH',
	SET_USER_ROLE_FETCH = 'SET_USER_ROLE_FETCH',
	SET_USERS = 'SET_USERS',
	SET_USER = 'SET_USER',
	CREATE_USER = 'CREATE_USER',
	CHECK_USER_INFO = 'CHECK_USER_INFO',
	UPDATE_USER_INFO_FETCH = 'UPDATE_USER_INFO_FETCH',
	UPDATE_USER_IPRS = 'UPDATE_USER_IPRS',
	DELETE_USER = 'DELETE_USER',
	LOADING_USERS = 'LOADING_USERS',
	LOADING_SETTINGS = 'LOADING_SETTINGS',
	UPDATE_COMPANY = 'UPDATE_COMPANY',
	CREATE_COMPANY = 'CREATE_COMPANY',
	GET_COMPANY_FETCH = 'GET_COMPANY_FETCH',
	SET_COMPANY = 'SET_COMPANY',
	SET_OPEN_CREATE_COMPANY = 'SET_OPEN_CREATE_COMPANY',
	SET_COMPANY_STATE = 'SET_COMPANY_STATE',
	CREATE_COMPANY_IPR = 'CREATE_COMPANY_IPR',
	DELETE_COMPANY_IPR = 'DELETE_COMPANY_IPR',
	UPDATE_COMPANY_IPR = 'UPDATE_COMPANY_IPR',
	CREATE_COPY_VERSION_IPR = 'CREATE_COPY_VERSION_IPR',
	SET_VERSION_IPR_NAME = 'SET_VERSION_IPR_NAME',
	GET_SOLUTIONS = 'GET_SOLUTIONS',
	SET_SOLUTIONS = 'SET_SOLUTIONS',
	SET_LOAD_SOLUTIONS = 'SET_LOAD_SOLUTIONS',
	SET_LOADING_SOLUTIONS_PAPE = 'SET_LOADING_SOLUTIONS_PAPE',
	SET_SOLUTIONS_PARAMS = 'SET_SOLUTIONS_PARAMS',
	REFRESH_SOLUTIONS = 'REFRESH_ONE_SOLUTIONS',
	SET_ADMIN_MODAL_STATE = 'SET_ADMIN_MODAL_STATE',
	SET_SOLUTION_OBJ = 'SET_SOLUTION_OBJ',
	CREATE_SOLUTION = 'CREATE_SOLUTION',
	CREATE_SOLUTION_LOCAL = 'CREATE_SOLUTION_LOCAL',
	EDIT_SOLUTION = 'EDIT_SOLUTION',
	EDIT_ONE_SOLUTION = 'EDIT_ONE_SOLUTION',

	EDIT_SOLUTION_LOCAL = 'EDIT_SOLUTION_LOCAL',
	DELETE_SOLUTION = 'DELETE_SOLUTION',
	DELETE_SOLUTION_LOCAL = 'DELETE_SOLUTION_LOCAL',
	SET_LOADING_ID = 'SET_LOADING_ID',
	SET_CREATE_LOAD = 'SET_CREATE_LOAD',
	DOWNLOAD_SOLUTIONS = 'DOWNLOAD_SOLUTIONS',
	SET_IS_DUPLICATE = 'SET_IS_DUPLICATE',
}

interface LoadingUsers {
	type: AdminPanelType.LOADING_USERS
	loading: boolean
}
interface SettingsUsers {
	type: AdminPanelType.LOADING_SETTINGS
	loading: boolean
}

interface GetAuthUserToken {
	type: AdminPanelType.GET_AUTH_USER_TOKEN
}
interface GetUsersFetch {
	type: AdminPanelType.GET_USERS_FETCH
}

interface SetUserRoleFetch {
	type: AdminPanelType.SET_USER_ROLE_FETCH
	userId: number
	role: number
}

interface GetUserRoleFetch {
	type: AdminPanelType.GET_USER_ROLE_FETCH
	id: number
}
interface CheckUserInfo {
	type: AdminPanelType.CHECK_USER_INFO
	id: number
}
interface UpdateUserInfo {
	type: AdminPanelType.UPDATE_USER_INFO_FETCH
	id: number
	user: IUser
	date_start: number | null
	date_end: number | null
	access: number
	idUserAccess?: number | null
}
interface UpdateUserIpr {
	type: AdminPanelType.UPDATE_USER_IPRS
	id: number
	user: IUser
	iprIds: number[]
}

interface CreateUsers {
	type: AdminPanelType.CREATE_USER
	user: IUser
	role: number
}
interface SetUsers {
	type: AdminPanelType.SET_USERS
	users: IUser[]
}
interface SetUser {
	type: AdminPanelType.SET_USER
	user: unknown
}

interface DeleteUser {
	type: AdminPanelType.DELETE_USER
	id: number
}

interface UpdateCompanyInfo {
	type: AdminPanelType.UPDATE_COMPANY
	company: any
}

interface CreateCompany {
	type: AdminPanelType.CREATE_COMPANY
	company: any
}

interface GetCompanyFetch {
	type: AdminPanelType.GET_COMPANY_FETCH
}

interface SetCompanies {
	type: AdminPanelType.SET_COMPANY
	companies: any[]
}

interface SetOpenCreateCompany {
	type: AdminPanelType.SET_OPEN_CREATE_COMPANY
	modal: IOpenModalCreateCompany
}

interface SetCompanyState {
	type: AdminPanelType.SET_COMPANY_STATE
	company: ICompany | null
}
interface CreateCompanyIpr {
	type: AdminPanelType.CREATE_COMPANY_IPR
	ipr: any
}
interface DeleteCompanyIpr {
	type: AdminPanelType.DELETE_COMPANY_IPR
	id: number
}
interface UpdateCompanyIpr {
	type: AdminPanelType.DELETE_COMPANY_IPR
	ipr: any
}
interface CreateCopyVersionIpr {
	type: AdminPanelType.CREATE_COPY_VERSION_IPR
	id: number
}
interface SetVersionIprName {
	type: AdminPanelType.SET_VERSION_IPR_NAME
	name: string
}

interface FetchSolutions {
	type: AdminPanelType.GET_SOLUTIONS
	params?: Record<string, string | number>
}
interface SetSolutions {
	type: AdminPanelType.SET_SOLUTIONS
	solutions: any[]
	resultsCnt: number
	order?: string
	techParamName?: string
	page?: number
}

interface SetLoadSolutions {
	type: AdminPanelType.SET_LOAD_SOLUTIONS
	loadSolutions: boolean
}

interface SetLoadingSolutionsPage {
	type: AdminPanelType.SET_LOADING_SOLUTIONS_PAPE
	loadingSolutionsPage: boolean
}

interface SetSolutionsParams {
	type: AdminPanelType.SET_SOLUTIONS_PARAMS
	params: {
		order?: string
		techParamName?: string
		page?: number
		is_duplicate?: number | string
		companyId?: string
	}
}
interface RefreshOneSolutions {
	type: AdminPanelType.REFRESH_SOLUTIONS
	id: number
}

interface SetAdminUncsModalState {
	type: AdminPanelType.SET_ADMIN_MODAL_STATE
	isAdminUncsModalState: {
		linkOpen: boolean
		editOpen: boolean
	}
}

interface SetSolutionObj {
	type: AdminPanelType.SET_SOLUTION_OBJ
	data: { techParamName?: string; uncCellId?: number } | null
}

interface CreateSolution {
	type: AdminPanelType.CREATE_SOLUTION
	solution: any
}

interface CreateSolutionLocal {
	type: AdminPanelType.CREATE_SOLUTION_LOCAL
	solution: any
}

interface EditSolution {
	type: AdminPanelType.EDIT_SOLUTION
	solution: any
	companyId: any
}

interface EditSolutionLocal {
	type: AdminPanelType.EDIT_SOLUTION_LOCAL
	solution: any
}
interface EditOneSolution {
	type: AdminPanelType.EDIT_ONE_SOLUTION
	solution: any
}

interface DeleteSolution {
	type: AdminPanelType.DELETE_SOLUTION
	id: any
}

interface DeleteSolutionLocal {
	type: AdminPanelType.DELETE_SOLUTION_LOCAL
	id: any
}

interface SetLoadingId {
	type: AdminPanelType.SET_LOADING_ID
	load: { editLoadingId: number | null; deleteLoadId: number | null }
}

interface SetCreateLoad {
	type: AdminPanelType.SET_CREATE_LOAD
	createLoad: boolean
}

interface SetIsDuplicate {
	type: AdminPanelType.SET_IS_DUPLICATE
	isDuplicate: number
}

export type AdminPanelTypes =
	| GetAuthUserToken
	| GetUsersFetch
	| GetUserRoleFetch
	| SetUserRoleFetch
	| SetUsers
	| SetUser
	| LoadingUsers
	| SettingsUsers
	| CheckUserInfo
	| UpdateUserInfo
	| UpdateUserIpr
	| CreateUsers
	| DeleteUser
	| UpdateCompanyInfo
	| CreateCompany
	| GetCompanyFetch
	| SetCompanies
	| SetOpenCreateCompany
	| SetCompanyState
	| CreateCompanyIpr
	| DeleteCompanyIpr
	| UpdateCompanyIpr
	| SetVersionIprName
	| CreateCopyVersionIpr
	| SetSolutions
	| FetchSolutions
	| SetLoadSolutions
	| SetLoadingSolutionsPage
	| SetSolutionsParams
	| RefreshOneSolutions
	| SetAdminUncsModalState
	| SetSolutionObj
	| CreateSolution
	| EditSolution
	| EditOneSolution
	| EditSolutionLocal
	| DeleteSolution
	| DeleteSolutionLocal
	| CreateSolutionLocal
	| SetLoadingId
	| SetCreateLoad
	| SetIsDuplicate
