import { createStyles, Fade, FormControl, makeStyles, MenuItem, Modal, Select, Theme } from '@material-ui/core'
import { setSolutionsParams } from '@root-gipro/modules/AdminPanel/store/actions'

import { changeTech, createSolution } from '@root-gipro/modules/userProjects/store/actions'
import { PrimaryBtn } from '@root-gipro/shared/Button/Button'
import { IRegion, IState } from '@root-gipro/store/interfaces'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			overflow: 'auto',
		},
	})
)
type RegionZone = {
	id: number
	name: string
}

interface ITechSolutionsModal {
	open: boolean
	handleClose: () => void
	solutionId?: any
	techParamNameProps?: string
	selectedRegionProps?: string
	selectedRegionZoneProps?: string
	selectedSolutionProps?: string
	isNewTechSolution: boolean
	solution?: any
}

const TechSolutionsModal: FC<ITechSolutionsModal> = ({
	open,
	handleClose,
	solutionId,
	techParamNameProps,
	selectedRegionProps,
	selectedRegionZoneProps,
	selectedSolutionProps,
	isNewTechSolution,
	solution,
}) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const companyId = localStorage.getItem('companyId')

	const [techParamName, setTechParamName] = useState(techParamNameProps || '')
	const [selectedRegion, setSelectedRegion] = useState(selectedRegionProps || '')
	const [selectedRegionZone, setSelectedRegionZone] = useState(selectedRegionZoneProps || '')
	const [selectedSolution, setSelectedSolution] = useState(selectedSolutionProps || '')

	const isFormValid = selectedRegion !== '' && selectedRegionZone !== '' && selectedSolution !== ''

	const { uncCells, originalObj } = useSelector((state: IState) => state.userProjects)

	const { regions } = useSelector((state: IState) => state.public)
	const { regionIds } = useSelector((state: IState) => state.userProjects.originalObj, shallowEqual)
	const { solutionsTech } = useSelector((state: IState) => state.adminPanel)

	const memoizedSolutions = useMemo(() => solutionsTech.solutions, [solutionsTech.solutions])

	const { filteredRegions, allRegionZones } = useMemo(() => {
		if (!regions?.length || !regionIds?.length) {
			return { filteredRegions: [], allRegionZones: [] }
		}

		const zones: RegionZone[] = []

		const filteredRegions = regions.filter((region: IRegion) => {
			const isMatch = regionIds.includes(region.id)
			if (isMatch && region.region_zones) {
				zones.push(...region.region_zones)
			}
			return isMatch
		})

		return { filteredRegions, allRegionZones: zones }
	}, [regions, regionIds.join(',')])

	const [errorData, setErrorData] = React.useState({
		techParamName: { error: false, message: '' },
	})
	useEffect(() => {
		setErrorData({
			techParamName: { error: false, message: '' },
		})
		if (open) {
			companyId && dispatch(setSolutionsParams({ page: 0, order: 'id DESC', companyId }))
		}
	}, [open, dispatch])

	const reset = () => {
		setTechParamName('')
		setSelectedRegion('')
		setSelectedRegionZone('')
		setSelectedSolution('')
	}

	const addSolution = () => {
		if (isNewTechSolution) {
			dispatch(
				createSolution({
					companyId,
					techParamName,
					uncTechSolutionId: selectedSolution,
					regionId: selectedRegion,
					regionZoneId: selectedRegionZone,
					count: 1,
					userProjectId: originalObj.id,
				})
			)
		} else {
			const updatedSolution: any = {
				id: solution.id,
			}

			if (Number(selectedRegion) !== solution.regionId) {
				updatedSolution.regionId = Number(selectedRegion)
			}

			if (Number(selectedRegionZone) !== solution.regionZoneId) {
				updatedSolution.regionZoneId = Number(selectedRegionZone)
			}

			dispatch(changeTech(updatedSolution))
		}
		reset()
		handleClose()
	}

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTechParamName(event.target.value)
	}

	const handleChangeRegion = (event: any) => {
		setSelectedRegion(event.target.value)
	}
	const handleChangeRegionZone = (event: any) => {
		setSelectedRegionZone(event.target.value)
	}
	const handleChangeSolution = (event: any) => {
		const { id, techParamName } = event.target.value
		setTechParamName(techParamName)
		setSelectedSolution(id)
	}

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby='simple-modal-title'
			aria-describedby='simple-modal-description'
			className={classes.modal}
			closeAfterTransition
		>
			<Fade in={open}>
				<form
					noValidate
					autoComplete='off'
					style={{
						background: 'white',
						padding: '32px',
						maxWidth: 550,
						width: '100%',
						outline: 'none',
						display: 'flex',
						flexDirection: 'column',
						gap: '12px',
					}}
				>
					{/* Техническое решение */}
					{/* <div>
						<label style={{ marginBottom: 8, display: 'block' }}>Техническое решение</label>
						<TextField
							id='techParamName'
							value={techParamName || ''}
							placeholder='Техническое решение'
							variant='outlined'
							onChange={handleChange}
							error={errorData.techParamName.error}
							helperText={errorData.techParamName.message}
							fullWidth
						/>
					</div> */}
					{isNewTechSolution && (
						<FormControl fullWidth variant='outlined'>
							<label id='region-select-label' style={{ marginBottom: 8, display: 'block' }}>
								Техническое решение
							</label>
							<Select
								labelId='region-select-label'
								value={selectedSolution}
								onChange={handleChangeSolution}
								label='Техническое решение'
							>
								{memoizedSolutions.map(solution => (
									<MenuItem key={solution.id} value={solution.id}>
										{solution.techParamCode} {solution.techParamName}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
					<FormControl fullWidth variant='outlined'>
						<label id='region-select-label' style={{ marginBottom: 8, display: 'block' }}>
							Регион
						</label>
						<Select labelId='region-select-label' value={selectedRegion} onChange={handleChangeRegion} label='Регион'>
							{filteredRegions.map(region => (
								<MenuItem key={region.id} value={region.id}>
									{region.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl fullWidth variant='outlined'>
						<label id='region-select-label' style={{ marginBottom: 8, display: 'block' }}>
							Региональные зоны
						</label>
						<Select
							labelId='region-select-label'
							value={selectedRegionZone}
							onChange={handleChangeRegionZone}
							label='Регион'
						>
							{allRegionZones.map(region => (
								<MenuItem key={region.id} value={region.id}>
									{region.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					<PrimaryBtn onClick={addSolution} style={{ width: '100%', marginTop: 16 }} disabled={!isFormValid}>
						{solutionId ? 'Сохранить' : 'Добавить'}
					</PrimaryBtn>
				</form>
			</Fade>
		</Modal>
	)
}

export default TechSolutionsModal
