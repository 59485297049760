import { AdminPanelType, AdminPanelTypes } from '@root-gipro/modules/AdminPanel/interfaces/admin-panel.actions'
import { IUser } from '@root-gipro/modules/AdminPanel/interfaces/user'
import { ICompany, IOpenModalCreateCompany } from '@root-gipro/store/interfaces'
import produce, { Draft } from 'immer'

export interface IAdminPanel {
	users: IUser[]
	usersLoading: boolean
	settingsLoading: boolean
	selectedUser: unknown
	companies: any[]
	openModalCompany: IOpenModalCreateCompany
	company: ICompany | null
	versionIpr: string
	solutionsTech: {
		solutions: any[]
		resultsCnt: number
		order?: string
		techParamName?: string
		page?: number
	}
	loadSolutions: boolean
	loadingSolutionsPage: boolean
	solutionsTechParams: {
		order: string
		techParamName: string
		page: number
		is_duplicate: number | string
		companyId: string
	}
	isAdminUncsModalState: {
		linkOpen: boolean
		editOpen: boolean
	}
	solutionObj: any
	editLoadingId: number | null
	deleteLoadId: number | null
	createLoad: boolean
	hasDuplicates: number
}

const initialState: IAdminPanel = {
	users: [],
	usersLoading: false,
	settingsLoading: false,
	selectedUser: null,
	companies: [],
	openModalCompany: {
		open: false,
		mode: '',
	},
	company: null,
	versionIpr: '',
	solutionsTech: {
		solutions: [],
		resultsCnt: 0,
		order: '',
		techParamName: '',
		page: 0,
	},
	loadSolutions: false,
	loadingSolutionsPage: false,
	solutionsTechParams: {
		order: '',
		techParamName: '',
		page: 0,
		is_duplicate: 0,
		companyId: '',
	},
	isAdminUncsModalState: {
		linkOpen: false,
		editOpen: false,
	},
	solutionObj: {},
	editLoadingId: null,
	deleteLoadId: null,
	createLoad: false,
	hasDuplicates: 0,
}

const adminPanel = (draft: Draft<IAdminPanel>, action: AdminPanelTypes) => {
	switch (action.type) {
		case AdminPanelType.SET_USER:
			draft.selectedUser = action.user
			break
		case AdminPanelType.SET_USERS:
			draft.users = action.users
			break
		case AdminPanelType.SET_COMPANY:
			draft.companies = action.companies
			break
		case AdminPanelType.LOADING_USERS:
			draft.usersLoading = action.loading
			break
		case AdminPanelType.LOADING_SETTINGS:
			draft.settingsLoading = action.loading
			break
		case AdminPanelType.SET_OPEN_CREATE_COMPANY:
			draft.openModalCompany = action.modal
			break
		case AdminPanelType.SET_COMPANY_STATE:
			draft.company = action.company
			break
		case AdminPanelType.SET_VERSION_IPR_NAME:
			draft.versionIpr = action.name
			break
		case AdminPanelType.SET_SOLUTIONS:
			if (
				action.page === 0 || // Если первая страница
				action.order !== draft.solutionsTech.order || // Если изменилась сортировка
				action.techParamName !== draft.solutionsTech.techParamName // Если изменился фильтр
			) {
				draft.solutionsTech = {
					solutions: action.solutions, // Перезаписываем массив решений
					resultsCnt: action.resultsCnt, // Обновляем общее количество
					order: action.order, // Сохраняем новую сортировку
					techParamName: action.techParamName, // Сохраняем новый фильтр
				}
			} else {
				draft.solutionsTech.solutions = [...draft.solutionsTech.solutions, ...action.solutions] // Добавляем данные при пагинации
				draft.solutionsTech.resultsCnt = action.resultsCnt
			}
			break

		case AdminPanelType.SET_LOAD_SOLUTIONS:
			draft.loadSolutions = action.loadSolutions
			break
		case AdminPanelType.SET_LOADING_SOLUTIONS_PAPE:
			draft.loadingSolutionsPage = action.loadingSolutionsPage
			break
		case AdminPanelType.SET_SOLUTIONS_PARAMS:
			draft.solutionsTechParams = {
				...draft.solutionsTechParams,
				...action.params,
			}
			break
		case AdminPanelType.SET_ADMIN_MODAL_STATE:
			draft.isAdminUncsModalState.editOpen = action.isAdminUncsModalState.editOpen
			draft.isAdminUncsModalState.linkOpen = action.isAdminUncsModalState.linkOpen
			break
		case AdminPanelType.SET_SOLUTION_OBJ:
			if (action.data == null) {
				draft.solutionObj = { data: {} } // Очищаем полностью
			} else {
				draft.solutionObj = {
					...draft.solutionObj,
					data: {
						...draft.solutionObj.data,
						...action.data, // Обновляем только переданные параметры
					},
				}
			}
			break
		case AdminPanelType.EDIT_SOLUTION_LOCAL:
			if (
				Array.isArray(draft.solutionsTech?.solutions) &&
				action.solution &&
				typeof action.solution.id !== 'undefined'
			) {
				draft.solutionsTech.solutions = draft.solutionsTech.solutions.map(sol =>
					sol.id === action.solution.id ? { ...sol, ...action.solution } : sol
				)
			}
			break
		case AdminPanelType.EDIT_ONE_SOLUTION:
			const index = draft.solutionsTech.solutions.findIndex(sol => sol.id === action.solution.id)
			if (index !== -1) {
				draft.solutionsTech.solutions[index] = action.solution
			}
			break
		case AdminPanelType.DELETE_SOLUTION_LOCAL:
			draft.solutionsTech.solutions = draft.solutionsTech.solutions.filter(sol => sol.id !== action.id)
			if (draft.solutionsTech.resultsCnt > 0) {
				draft.solutionsTech.resultsCnt -= 1
			}
			break
		case AdminPanelType.CREATE_SOLUTION_LOCAL:
			draft.solutionsTech.solutions = [action.solution, ...draft.solutionsTech.solutions]
			draft.solutionsTech.resultsCnt += 1
			break
		case AdminPanelType.SET_LOADING_ID:
			draft.editLoadingId = action.load.editLoadingId
			draft.deleteLoadId = action.load.deleteLoadId
			break
		case AdminPanelType.SET_CREATE_LOAD:
			draft.createLoad = action.createLoad
			break
		case AdminPanelType.SET_IS_DUPLICATE:
			draft.hasDuplicates = action.isDuplicate
			break
		default:
			return draft
	}
}
export const adminPanelReducer = produce(adminPanel, initialState)
