import {
	getSelectCommonProject,
	getUncsCommonProject,
	getUncTechnicalSpecificationsCommonProject,
} from '@root-gipro/modules/commonProjects/store/actions'
import { fetchUncsArchive, fetchUserProjectArchive } from '@root-gipro/modules/userArchive/store/actions'
import { fetchUncs, fetchUserProject, getProjectTechSolutions } from '@root-gipro/modules/userProjects/store/actions'
import { Routes } from '@root-gipro/router'
import { BreadcrumbsComponent } from '@root-gipro/shared/BreadcrumbsComponent/BreadcrumbsComponent'
import { usePageLink } from '@root-gipro/shared/hooks/usePageLink'
import { TypesNavLink } from '@root-gipro/shared/TabsComponents/tabs.props'
import { IState } from '@root-gipro/store/interfaces/state'
import { searchByKey } from '@root-gipro/utils/helpers/common'
import React, { FC, memo, MutableRefObject, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

const SelectedContainer: FC = memo(({ children }) => {
	const { selectedProject, isProjectLoadedFromCard } = useSelector((state: IState) => state.commonProjects)
	const { selectedPurchase } = useSelector((state: IState) => state.purchases)
	const { groups } = useSelector((state: IState) => state.public)
	const [typesRoute, setTypesRoute] = useState<TypesNavLink[]>()
	const [group, setGroup] = useState<number>()
	const { pathRoute, nameLink }: { currentPath: string; pathRoute: string; nameLink: string } = usePageLink()
	const { pathname } = useLocation()

	const currentUrl = pathRoute.split('/')[1]
	const currentState = currentUrl === 'user-archive' ? 'userArchives' : 'userProjects'
	const { originalObj, projectObj } = useSelector((state: IState) => state[currentState])
	const dispatch = useDispatch()
	const urlId: MutableRefObject<string | undefined> = useRef()
	const prevUncSortRef: any = useRef()
	const [urlIdInPath, setUrlIdInPath] = useState<string>(pathname.replace(/[^\d]/g, ''))

	useEffect(() => {
		setUrlIdInPath(pathname.replace(/[^\d]/g, ''))
	}, [pathname])

	useEffect(() => {
		urlId.current = urlIdInPath
		if (urlId.current !== '0' && pathRoute === Routes.USER_PROJECTS) {
			dispatch(fetchUserProject(urlId.current))
			dispatch(fetchUncs(urlId.current))
		} else if (urlId.current !== '0' && pathRoute === Routes.USER_ARCHIVE) {
			dispatch(fetchUserProjectArchive(urlId.current))
			dispatch(fetchUncsArchive(urlId.current))
		}
	}, [urlIdInPath])
	useEffect(()=>{
		urlId.current = urlIdInPath
		if (urlId.current !== '0' && pathRoute === Routes.USER_PROJECTS) {
			
		// dispatch(getProjectTechSolutions(urlId.current))
		}
	},[urlIdInPath])
	useEffect(() => {
		urlId.current = pathname.replace(/[^\d]/g, '')

		const checkUrl =
			pathname.includes('parameters') || pathname.includes('rates') || pathname.includes('construction-resources')

		if (
			pathRoute === Routes.COMMON_PROJECTS &&
			checkUrl &&
			urlId.current &&
			groups.length &&
			!selectedProject?.groupId
		) {
			!isProjectLoadedFromCard && dispatch(getSelectCommonProject(urlId.current))
			dispatch(getUncsCommonProject(urlId.current))
			dispatch(getUncTechnicalSpecificationsCommonProject(urlId.current))
		}

		if (
			urlId.current !== '0' &&
			pathRoute === Routes.USER_PROJECTS &&
			groups.length &&
			prevUncSortRef.current !== projectObj.unc_sort
		) {
			if (prevUncSortRef.current !== projectObj.unc_sort) {
				// Только если версия расчета-unc_sort изменился, вызываем экшены
				dispatch(fetchUserProject(urlId.current))
				dispatch(fetchUncs(urlId.current))

				prevUncSortRef.current = projectObj.unc_sort
			}
		} else if (urlId.current !== '0' && pathRoute === Routes.USER_ARCHIVE) {
			dispatch(fetchUserProjectArchive(urlId.current))
			dispatch(fetchUncsArchive(urlId.current))
		}
		const routeCommonProjectsParameters = Routes.COMMON_PROJECTS_PARAMETERS.replace(/:id/g, urlId.current)
		const routeCommonProjectsRates = Routes.COMMON_PROJECTS_RATES.replace(/:id/g, urlId.current)
		const routeCommonProjectsTechnicalSolutions = Routes.COMMON_PROJECTS_TECHNICAL_SOLUTIONS.replace(/:id/g, urlId.current)


		const routeUserProjectsRates = Routes.USER_PROJECTS_RATES.replace(/:id/g, urlId.current)
		const routeUserProjectsParameters = Routes.USER_PROJECTS_PARAMETERS.replace(/:id/g, urlId.current)
		const routeUserProjectsTechnicalSolutions = Routes.USER_PROJECTS_TECHNICAL_SOLUTIONS.replace(/:id/g, urlId.current)
		const routeUserArchivesRates = Routes.USER_ARCHIVE_RATES.replace(/:id/g, urlId.current)
		const routeUserArchivesParameters = Routes.USER_ARCHIVE_PARAMETERS.replace(/:id/g, urlId.current)
		const routeUserArchivesTechnicalSolutions = Routes.USER_ARCHIVE_TECHNICAL_SOLUTIONS.replace(/:id/g, urlId.current)


		if (
			pathname === routeCommonProjectsParameters &&
			groups &&
			selectedProject &&
			selectedProject?.groupId !== '' &&
			selectedProject?.groupId !== undefined
		) {
			setGroup(searchByKey(groups, 'id', selectedProject?.groupId))
		}
		const typesRoute = [
			{
				title: 'Параметры',
				path:
					pathRoute === Routes.COMMON_PROJECTS
						? routeCommonProjectsParameters
						: pathRoute === Routes.USER_PROJECTS
						? routeUserProjectsParameters
						: routeUserArchivesParameters,
				tags: [Routes.COMMON_PROJECTS, Routes.USER_PROJECTS, Routes.USER_ARCHIVE],
			},
			{
				title: 'Расценки',
				path:
					pathRoute === Routes.COMMON_PROJECTS
						? routeCommonProjectsRates
						: pathRoute === Routes.USER_PROJECTS
						? routeUserProjectsRates
						: routeUserArchivesRates,
				tags: [Routes.COMMON_PROJECTS, Routes.USER_PROJECTS, Routes.USER_ARCHIVE],
			},
			{
				title: 'Технические решения',
				path:
					pathRoute === Routes.COMMON_PROJECTS
						? routeCommonProjectsTechnicalSolutions
						: pathRoute === Routes.USER_PROJECTS
						? routeUserProjectsTechnicalSolutions
						: routeUserArchivesTechnicalSolutions,
				tags: [Routes.COMMON_PROJECTS, Routes.USER_PROJECTS, Routes.USER_ARCHIVE],
			},
		]
		
		setTypesRoute(typesRoute)
	}, [groups, pathname, selectedProject?.groupId, selectedPurchase?.company_id])
	return (
		<>
			<div className='container'>
				<BreadcrumbsComponent
					typesRoute={typesRoute as TypesNavLink[]}
					group={groups[group as number] || originalObj}
					path={pathRoute && pathRoute}
					nameLink={nameLink}
					urlId={urlId.current}
				/>
			</div>
			{children}
		</>
	)
})
export default SelectedContainer
