import { IconButton, TableCell, TableRow, Tooltip } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { setSolutionObj } from '@root-gipro/modules/AdminPanel/store/actions'
import TechSolutionsModal from '@root-gipro/modules/userProjects/components/Project/ProjectTechSolutions/TechSolutionsModal'
import { changeTechCount, deleteProjectTechSolution } from '@root-gipro/modules/userProjects/store/actions'
import { removeSpaceFromNumber } from '@root-gipro/modules/userProjects/utils/removeSpaceFromNumber'
import styles from '@root-gipro/shared/Table/styles/Table.module.scss'
import { IState } from '@root-gipro/store/interfaces'
import { intFormat } from '@root-gipro/utils/helpers/common'
import cn from 'classnames'
import React, { forwardRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

interface IProjectSolutionsTableRowProps {
	solution: any
}

const TechSolutionsTableRow = forwardRef<HTMLTableRowElement, IProjectSolutionsTableRowProps>(({ solution }, ref) => {
	const dispatch = useDispatch()
	const [open, setOpen] = useState<boolean>(false)
	const [count, setCount] = useState<number | null>(null)
	const [initialCount, setInitialCount] = useState<number | null>(null)
	const { projectObj } = useSelector((state: IState) => state.userProjects)

	const handleOpen = () => {
		setOpen(true)
		dispatch(setSolutionObj(null))
	}

	const handleClose = () => {
		setOpen(false)
		// dispatch(setAdminUncsModalState({ linkOpen: false, editOpen: false }))
	}

	useEffect(() => {
		if (solution?.count !== undefined) {
			setCount(solution.count) // начальное значение
			setInitialCount(solution.count)
		}
	}, [solution])

	return (
		<TableRow ref={ref} className='adminPanel__tableRow'>
			<TableCell className='adminPanelCell__solution' align='center' style={{ width: '10%' }}>
				<IconButton
					size='small'
					aria-label='Удалить'
					onClick={() => dispatch(deleteProjectTechSolution(solution?.id, solution?.userProjectId))}
				>
					<DeleteIcon fontSize='small' />
				</IconButton>
				<IconButton
					size='small'
					aria-label='Изменить данные'
					// disabled={editLoadingId == solution.id}
					onClick={handleOpen}
				>
					<EditIcon fontSize='small' />
				</IconButton>
			</TableCell>

			<TableCell className='adminPanelCell__solution' align='center' style={{ width: '20%' }}>
				{projectObj?.code}
			</TableCell>

			<Tooltip title={solution?.uncTechSolution?.techParamName}>
				<TableCell className='adminPanelCell__solution' style={{ width: '20%' }}>
					<div className='adminPanelCell__solution_name'> {solution?.uncTechSolution?.techParamName}</div>
				</TableCell>
			</Tooltip>
			<TableCell className='adminPanelCell__solution' style={{ width: '20%' }}>
				<div className='adminPanelCell__solution_name'> {solution?.uncCell?.code}</div>
			</TableCell>

			<TableCell align='center' style={{ width: '20%' }}>
				{solution.uncCell?.unit || '-'}
			</TableCell>
			<TableCell align='center' style={{ width: '10%' }}>
				<div className={cn(styles.tableCell)}>
					<input
						onChange={e => {
							setCount(intFormat(e.currentTarget.value))
						}}
						onBlur={e => {
							const parsed = +removeSpaceFromNumber(e.currentTarget.value)
							if (parsed !== initialCount) {
								dispatch(changeTechCount(solution?.id, parsed))
								setInitialCount(parsed) // обновляем initial
							}
						}}
						style={{ borderColor: '#e0e0e0' }}
						className={styles.uncCounter}
						value={count ? intFormat(count) : ''}
					/>
				</div>
			</TableCell>

			<TableCell className='adminPanelCell__solution' align='center' style={{ width: '15%' }}>
				{solution?.region?.name || '-'}
			</TableCell>
			{open && (
				<TechSolutionsModal
					open={open}
					handleClose={handleClose}
					techParamNameProps={solution.uncTechSolution.techParamName}
					selectedRegionProps={solution.regionId}
					selectedRegionZoneProps={solution.regionZoneId}
					selectedSolutionProps={solution.uncTechSolutionId}
					isNewTechSolution={false}
					solution={solution}
				/>
			)}
		</TableRow>
	)
})

export default TechSolutionsTableRow
