import React, { FC, useEffect } from 'react'
import { ModalProvider } from '@root-gipro/context/modal-provider'
import CommonProjectsContainer from '@root-gipro/modules/commonProjects/CommonProjectsContainer'
import { Parameters, Rates, SelectedContainer } from '@root-gipro/modules/commonProjects/containers/SelectedProject'
import Header from '@root-gipro/modules/header/containers/'
import { Notify } from '@root-gipro/modules/notify/Notify'
import { UserArchive } from '@root-gipro/modules/userArchive/containers'
import { CheckPlate } from '@root-gipro/modules/userProjects/components/Project/CheckPlate/CheckPlate'
import { UserProjects } from '@root-gipro/modules/userProjects/containers'
import { history, Routes } from '@root-gipro/router'
import { authorize, fetchPublicInfo } from '@root-gipro/store/actions'
import { IState } from '@root-gipro/store/interfaces/state'
import '@root-gipro/styles/index.scss'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Router, Switch } from 'react-router-dom'

import '@root-gipro/modules/admin/styles/Admin.scss'
import '@root-gipro/modules/AdminPanel/styles/adminPanel.scss'
import '@root-gipro/modules/commonProjects/styles/Projects.scss'
import Faq from '@root-gipro/components/Faq'

import AdminContainer from '@root-gipro/modules/AdminPanel/AdminContainer'
import { UncTablContainer } from '@root-gipro/modules/userProjects/components/Project/UncTable/UncTablContainer'
import TechSolutions from '@root-gipro/modules/userProjects/components/Project/ProjectTechSolutions/TechSolutions'

const App: FC = () => {
	const { isFilterOpen } = useSelector((state: IState) => state.maket)
	const { accessToken } = useSelector((state: IState) => state.auth)

	const dispatch = useDispatch()

	useEffect(() => {
		if (accessToken) {
			dispatch(fetchPublicInfo())
		} else {
			dispatch(authorize())
		}
	}, [accessToken])

	return (
		<Router history={history}>
			{accessToken && (
				<ModalProvider>
					<div className={`wrapper-for-animation ${isFilterOpen && 'is-filter-open'}`}>
						<Header />
						<div>
							<Switch>
								<Route exact path='/' render={() => <Redirect to={Routes.COMMON_PROJECTS} />} />

								<Route exact path={Routes.COMMON_PROJECTS} component={CommonProjectsContainer} />
								<Route exact path={Routes.USER_PROJECTS} component={UserProjects} />
								<Route exact path={Routes.USER_ARCHIVE} component={UserArchive} />

								<Route path={Routes.ADMIN} component={AdminContainer} />

								<SelectedContainer>
									<Route exact path={Routes.COMMON_PROJECTS_PARAMETERS} component={Parameters} />
									<Route exact path={Routes.COMMON_PROJECTS_RATES} component={Rates} />
									{/* <Route exact path={Routes.COMMON_PROJECTS_CONSTRUCTION_RESOURCES} component={ConstructionResources} /> */}
									<Route exact path={Routes.USER_PROJECTS_PARAMETERS} component={CheckPlate} />
									<Route exact path={Routes.USER_PROJECTS_RATES} component={UncTablContainer} />
									<Route exact path={Routes.USER_ARCHIVE_PARAMETERS} component={CheckPlate} />
									<Route exact path={Routes.USER_ARCHIVE_RATES} component={UncTablContainer} />
									<Route exact path={Routes.USER_PROJECTS_TECHNICAL_SOLUTIONS} component={TechSolutions} />
									<Route exact path={Routes.USER_ARCHIVE_TECHNICAL_SOLUTIONS} component={TechSolutions} />


									
								</SelectedContainer>
							</Switch>
						</div>
						<Notify />
					</div>
				</ModalProvider>
			)}
		</Router>
	)
}

export default App
