import { AdminPanelType } from '@root-gipro/modules/AdminPanel/interfaces/admin-panel.actions'
import { CompanyIPR, IUser } from '@root-gipro/modules/AdminPanel/interfaces/user'
import { ICompany, IOpenModalCreateCompany } from '@root-gipro/store/interfaces'

export const getAuthUserToken = () => ({
	type: AdminPanelType.GET_AUTH_USER_TOKEN,
})
export const getUsersFetch = () => ({
	type: AdminPanelType.GET_USERS_FETCH,
})

export const getUserRoleFetch = (id: number) => ({
	type: AdminPanelType.GET_USER_ROLE_FETCH,
	id,
})

export const setUserRoleFetch = (userId: number, role: number) => ({
	type: AdminPanelType.SET_USER_ROLE_FETCH,
	userId,
	role,
})

export const loadingUsers = (loading: boolean) => ({
	type: AdminPanelType.LOADING_USERS,
	loading,
})

export const loadingSettings = (loading: boolean) => ({
	type: AdminPanelType.LOADING_SETTINGS,
	loading,
})

export const setUsers = (users: IUser[]) => ({
	type: AdminPanelType.SET_USERS,
	users,
})

export const createUsers = (user: IUser) => ({
	type: AdminPanelType.CREATE_USER,
	user,
})

export const setUser = (user: unknown) => ({
	type: AdminPanelType.SET_USER,
	user,
})

export const checkUserInfo = (id: number) => ({
	type: AdminPanelType.CHECK_USER_INFO,
	id,
})

export const deleteUserFetch = (id: number) => ({
	type: AdminPanelType.DELETE_USER,
	id,
})
export const updateUserInfo = (
	id: number,
	user: IUser,
	date_start: number | null,
	date_end: number | null,
	access: boolean,
	idUserAccess?: number | undefined
) => ({
	type: AdminPanelType.UPDATE_USER_INFO_FETCH,
	id,
	user,
	date_start,
	date_end,
	access,
	idUserAccess,
})
export const updateUserIpr = (id: number, user: IUser, iprIds: CompanyIPR[]) => ({
	type: AdminPanelType.UPDATE_USER_IPRS,
	id,
	user,
	iprIds,
})

export const updateCompanyInfo = (company: any) => ({
	type: AdminPanelType.UPDATE_COMPANY,
	company,
})

export const createCompany = (company: any) => ({
	type: AdminPanelType.CREATE_COMPANY,
	company,
})

export const getCompanyGroupsFetch = () => ({
	type: AdminPanelType.GET_COMPANY_FETCH,
})

export const setCompanies = (companies: any) => ({
	type: AdminPanelType.SET_COMPANY,
	companies,
})

export const setOpenModalCreateCompany = (modal: IOpenModalCreateCompany) => ({
	type: AdminPanelType.SET_OPEN_CREATE_COMPANY,
	modal,
})

export const setCompanyState = (company: ICompany | null) => ({
	type: AdminPanelType.SET_COMPANY_STATE,
	company,
})

export const сreateCompanyIpr = (ipr: any) => ({
	type: AdminPanelType.CREATE_COMPANY_IPR,
	ipr,
})
export const deleteCompanyIpr = (id: number) => ({
	type: AdminPanelType.DELETE_COMPANY_IPR,
	id,
})
export const updateCompanyIpr = (ipr: any) => ({
	type: AdminPanelType.UPDATE_COMPANY_IPR,
	ipr,
})
export const createCopyVersionIpr = (id: number) => ({
	type: AdminPanelType.CREATE_COPY_VERSION_IPR,
	id,
})
export const setVersionIprName = (name: string) => ({
	type: AdminPanelType.SET_VERSION_IPR_NAME,
	name,
})
export const fetchSolutions = (params?: any) => ({
	type: AdminPanelType.GET_SOLUTIONS,
	params,
})
export const setSolutions = (
	solutions: any[],
	resultsCnt: number,
	order?: string,
	techParamName?: string,
	page?: number
) => ({
	type: AdminPanelType.SET_SOLUTIONS,
	solutions,
	resultsCnt,
	order,
	techParamName,
	page,
})

export const setLoadSolutions = (loadSolutions?: boolean) => ({
	type: AdminPanelType.SET_LOAD_SOLUTIONS,
	loadSolutions,
})

export const setLoadingSolutionsPage = (loadingSolutionsPage?: boolean) => ({
	type: AdminPanelType.SET_LOADING_SOLUTIONS_PAPE,
	loadingSolutionsPage,
})

export const setSolutionsParams = (params: {
	order?: string
	techParamName?: string
	page?: number
	is_duplicate?: number | string
	companyId?: string
}) => ({
	type: AdminPanelType.SET_SOLUTIONS_PARAMS,
	params,
})

export const setAdminUncsModalState = (isAdminUncsModalState: { linkOpen: boolean; editOpen: boolean }) => ({
	type: AdminPanelType.SET_ADMIN_MODAL_STATE,
	isAdminUncsModalState,
})

export const setSolutionObj = (data: Partial<{ id?: string; techParamName?: string; uncCellId?: number } | null>) => ({
	type: AdminPanelType.SET_SOLUTION_OBJ, // Обновленный тип экшена
	data, // Передаем объект с новыми параметрами
})

export const createSolution = (solution: any) => ({
	type: AdminPanelType.CREATE_SOLUTION,
	solution,
})

export const createSolutionLocal = (solution: any) => ({
	type: AdminPanelType.CREATE_SOLUTION_LOCAL,
	solution,
})

export const editSolution = (solution: any, companyId: any) => ({
	type: AdminPanelType.EDIT_SOLUTION,
	solution,
	companyId,
})

export const editSolutionLocal = (solution: any) => ({
	type: AdminPanelType.EDIT_SOLUTION_LOCAL,
	solution,
})

export const setSolutionById = (solution: any) => ({
	type: AdminPanelType.EDIT_ONE_SOLUTION,
	solution,
})
export const refreshOneSolutionAction = (id: string) => ({
	type: AdminPanelType.REFRESH_SOLUTIONS,
	id,
})

export const deleteSolution = (id: number) => ({
	type: AdminPanelType.DELETE_SOLUTION,
	id,
})

export const deleteSolutionLocal = (id: number) => ({
	type: AdminPanelType.DELETE_SOLUTION_LOCAL,
	id,
})

export const setLoadingId = (load: { editLoadingId: number | null; deleteLoadId: number | null }) => ({
	type: AdminPanelType.SET_LOADING_ID,
	load,
})

export const setCreateLoad = (createLoad: boolean) => ({
	type: AdminPanelType.SET_CREATE_LOAD,
	createLoad,
})

export const downloadSolutions = () => ({
	type: AdminPanelType.DOWNLOAD_SOLUTIONS,
})

export const setIsDuplicateState = (isDuplicate: number) => ({
	type: AdminPanelType.SET_IS_DUPLICATE,
	isDuplicate,
})
