import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	createStyles,
	makeStyles,
} from '@material-ui/core'
import Loader from '@root-gipro/shared/Loader/Loader'
import React, { FC, useCallback, useRef } from 'react'
import TechSolutionsTableRow from './TechSolutionsRow'

interface IAdminSolutionsTable {
	solution: any[]
	loadMore: () => void
	loadSolutions: boolean
	setSort: (mode: any) => void
	sort: any
	resultsCnt: any
}

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			'& .MuiTableCell-root': {
				padding: '6px 12px',
			},
		},
		sortIcon: {
			width: '13px',
			verticalAlign: 'bottom',
			marginLeft: '5px',
			transition: 'transform 0.2s ease, color 0.2s ease',
		},
		sortIconActive: {
			color: '#1976d2', // Подсветка активной сортировки
		},
		sortIconDesc: {
			transform: 'rotate(180deg)', // Переворачиваем, если сортировка по убыванию
		},
		textWithIcon: {
			display: 'inline-flex',
			alignItems: 'center',
			cursor: 'pointer',
			'&:hover $sortIcon': {
				transform: 'scale(1.2)',
				color: '#1976d2',
			},
		},
	})
)

const TechSolutionsTable: FC<IAdminSolutionsTable> = ({
	solution,
	loadMore,
	loadSolutions,
	setSort,
	sort,
	resultsCnt,
}) => {
	const classes = useStyles()

	// Функция переключения сортировки
	const toggleSort = (field: 'techParamCode' | 'techParamName' | 'id') => {
		setSort((prev: string) => (prev === field ? `${field} DESC` : field))
	}

	// Функция для определения активного класса сортировки
	const getSortIconClass = (field: string) => {
		if (sort.startsWith(field)) {
			return `${classes.sortIcon} ${classes.sortIconActive} ${sort.includes('DESC') ? classes.sortIconDesc : ''}`
		}
		return classes.sortIcon
	}

	const observer = useRef<IntersectionObserver | null>(null)
	const lastElementRef = useCallback(
		(node: HTMLTableRowElement | null) => {
			if (observer.current) observer.current.disconnect()

			observer.current = new IntersectionObserver(entries => {
				if (entries[0].isIntersecting) {
					loadMore()
				}
			})

			if (node) observer.current.observe(node)
		},
		[loadMore]
	)

	return (
		<TableContainer component={Paper} className={classes.root}>
			<div style={{ width: '100%', overflowX: 'auto', height: 'auto' }}>
				<Table size='small' stickyHeader aria-label='admin panel table'>
					<TableHead>
						<TableRow>
							<TableCell style={{ width: '10%' }} align='center'>
								Действия
							</TableCell>
							<TableCell align='center' style={{ width: '20%' }} className={classes.root}>
								Идентификатор проекта
							</TableCell>
							<TableCell style={{ width: '25%' }}>
								Техническое решение
								{/* <SwapVertOutlined className={getSortIconClass('techParamName')} /> */}
							</TableCell>
							<TableCell style={{ width: '25%' }}>УНЦ</TableCell>
							<TableCell style={{ width: '20%' }} align='center'>
								Единица измерения
							</TableCell>
							<TableCell style={{ width: '10%' }} align='center'>
								Количество
							</TableCell>
							<TableCell style={{ width: '15%' }} align='center'>
								Регион
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							// loadingSolutionsPage ? (
							// 	<tr>
							// 		<td colSpan={100} style={{ textAlign: 'center', padding: '10px' }}>
							// 			<Loader />
							// 		</td>
							// 	</tr>
							// ) :
							solution?.length > 0 ? (
								solution?.map((item, index) => {
									const isLastItem = index === solution?.length - 1
									return (
										<TechSolutionsTableRow ref={isLastItem ? lastElementRef : null} solution={item} key={item.id} />
									)
								})
							) : (
								<tr>
									<td colSpan={100} style={{ textAlign: 'center', padding: '10px' }}>
										Нет данных для отображения
									</td>
								</tr>
							)
						}

						{loadSolutions && (
							<tr>
								<td colSpan={100} style={{ textAlign: 'center', padding: '10px' }}>
									<Loader />
								</td>
							</tr>
						)}
					</TableBody>
				</Table>
			</div>
		</TableContainer>
	)
}

export default TechSolutionsTable
