import { createStyles, IconButton, makeStyles, TextField, Theme } from '@material-ui/core'
import style from '@root-gipro/modules/AdminPanel/styles/adminPanel.module.scss'
import { IState } from '@root-gipro/store/interfaces'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Add } from '@material-ui/icons'
import GetAppIcon from '@material-ui/icons/GetApp'
import { setSolutionObj } from '@root-gipro/modules/AdminPanel/store/actions'
import TechSolutionsModal from '@root-gipro/modules/userProjects/components/Project/ProjectTechSolutions/TechSolutionsModal'
import { downloadSolutionsUP, getProjectTechSolutions } from '@root-gipro/modules/userProjects/store/actions'
import { SecondaryBtn } from '@root-gipro/shared/Button/Button'
import { useLocation } from 'react-router-dom'
import TechSolutionsTable from './TechSolutionsTable'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			borderRadius: 8,
		},
		textField: {
			'& .MuiInputBase-root': {
				width: '300px',
			},
		},
	})
)

const TechSolutions = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const { techSolutions, loadSolutions, originalObj } = useSelector((state: IState) => state.userProjects)

	// Состояния для пагинации
	const [page, setPage] = useState(0)
	const [sort, setSort] = useState('id')
	const [filterName, setFilterName] = useState('')
	const [open, setOpen] = useState<boolean>(false)

	const { pathname } = useLocation()

	const [urlIdInPath, setUrlIdInPath] = useState<string>(pathname.replace(/[^\d]/g, ''))

	useEffect(() => {
		setUrlIdInPath(pathname.replace(/[^\d]/g, ''))
	}, [pathname])

	const loadMoreSolutions = () => {
		if (loadSolutions || techSolutions?.resultsCnt <= techSolutions?.userTechSolutions?.length) return
		const nextPage = (techSolutions?.page ?? 0) + 1
		dispatch(getProjectTechSolutions(urlIdInPath, { page: nextPage }))
	}

	useEffect(() => {
		dispatch(getProjectTechSolutions(urlIdInPath, { page: 0, order: sort }))
	}, [sort])

	const prevFilterRef = useRef(filterName) // Храним предыдущее значение фильтра

	const handleBlur = () => {
		if (prevFilterRef.current !== filterName) {
			// Только если фильтр изменился
			dispatch(getProjectTechSolutions(urlIdInPath, { page: 0, order: sort }))
			prevFilterRef.current = filterName // Обновляем предыдущее значение
		}
	}

	const handleOpen = () => {
		setOpen(true)
		dispatch(setSolutionObj(null))
	}

	const handleClose = () => {
		setOpen(false)
		// dispatch(setAdminUncsModalState({ linkOpen: false, editOpen: false }))
	}

	return (
		<div className='adminPanel-container'>
			<div className={style.adminPanel__header}>
				<div className={style.adminPanel__headerLeft}>
					<IconButton onClick={handleOpen} classes={classes}>
						<Add />
					</IconButton>
					<TextField
						className={classes.textField}
						variant='outlined'
						placeholder='Техническое решение'
						value={filterName}
						onChange={e => setFilterName(e.target.value)}
						onBlur={handleBlur}
					/>
				</div>
				<div className={style.adminPanel__header__export_container}>
					<p>
						Всего технических решений: <span className={style.boldText}>{techSolutions?.resultsCnt ?? 0}</span>
					</p>
					<SecondaryBtn
						className={style.add_btn}
						onClick={() => dispatch(downloadSolutionsUP(originalObj.id as string))}
					>
						<GetAppIcon style={{ color: '#3f4f5e' }} fontSize='small' />
						Экспорт
					</SecondaryBtn>
				</div>
			</div>
			<TechSolutionsTable
				solution={techSolutions?.userTechSolutions}
				loadMore={loadMoreSolutions}
				loadSolutions={loadSolutions}
				sort={sort}
				setSort={setSort}
				resultsCnt={techSolutions?.resultsCnt}
			/>
			<TechSolutionsModal open={open} handleClose={handleClose} isNewTechSolution={true} />
		</div>
	)
}

export default TechSolutions
