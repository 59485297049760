import { CircularProgress, IconButton, TableCell, TableRow, Tooltip } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import LinkIcon from '@material-ui/icons/Link'
import { ModalContext } from '@root-gipro/context/modal-context'
import { setAdminUncsModalState, setSolutionObj } from '@root-gipro/modules/AdminPanel/store/actions'
import { IState } from '@root-gipro/store/interfaces'
import React, { forwardRef, useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AdminCreateSolution from '../AdminCreateSolution/AdminCreateSolution'

interface IAdminSolutionsTableRowProps {
	solution: {
		id: any
		is_duplicate: number
		dulicatedUncCellId?: boolean
		dulicatedTechParamName?: boolean
		techParamCode: string
		techParamName: string
		unit?: string
		code?: string
		uncCellId?: number
	}
	setSolutionInfo: (mode: any) => void
}

const AdminSolutionsTableRow = forwardRef<HTMLTableRowElement, IAdminSolutionsTableRowProps>(
	({ solution, setSolutionInfo }, ref) => {
		const { setAnchorEl } = useContext(ModalContext)
		const { editLoadingId, deleteLoadId } = useSelector((state: IState) => state.adminPanel)

		const dispatch = useDispatch()
		const [openModal, setOpenModal] = useState<boolean>(false)

		const handleClose = () => {
			setOpenModal(false)
		}

		return (
			<>
				<TableRow ref={ref} className='adminPanel__tableRow'>
					<TableCell className='adminPanelCell__solution' align='center' style={{ width: '10%' }}>
						<IconButton
							size='small'
							aria-label='Удалить'
							data-popup='delete_solution'
							disabled={deleteLoadId == solution.id}
							onClick={e => {
								setAnchorEl!(e.currentTarget)
								setSolutionInfo(solution)
							}}
						>
							{deleteLoadId === solution.id ? <CircularProgress size={20} /> : <DeleteIcon fontSize='small' />}
						</IconButton>
						<IconButton
							size='small'
							aria-label='Изменить данные'
							disabled={editLoadingId == solution.id}
							onClick={() => {
								setOpenModal(true)
								dispatch(
									setSolutionObj({
										uncCellId: solution?.uncCellId,
										techParamName: solution.techParamName,
										id: solution.id,
									})
								)
							}}
						>
							{editLoadingId === solution.id ? (
								<CircularProgress size={20} /> // Прелоадер во время загрузки
							) : (
								<EditIcon fontSize='small' />
							)}
						</IconButton>
					</TableCell>

					<TableCell className='adminPanelCell__solution' align='center' style={{ width: '15%' }}>
						{solution.techParamCode}
					</TableCell>

					<Tooltip title={solution.techParamName}>
						<TableCell
							className='adminPanelCell__solution'
							style={{ width: '40%', color: solution.dulicatedTechParamName ? 'red' : 'black' }}
						>
							<div className='adminPanelCell__solution_name'>{solution.techParamName}</div>
						</TableCell>
					</Tooltip>

					<TableCell align='center' style={{ width: '20%' }}>
						{solution?.unit || '-'}
					</TableCell>

					<TableCell
						className='adminPanelCell__solution'
						align='center'
						style={{ width: '15%', color: solution.dulicatedUncCellId ? 'red' : 'black' }}
					>
						<Tooltip title={solution?.code || 'Нет данных'}>
							<IconButton
								type='button'
								onClick={() => {
									dispatch(setAdminUncsModalState({ linkOpen: true, editOpen: false }))
									dispatch(
										setSolutionObj({
											uncCellId: solution?.uncCellId,
											techParamName: solution.techParamName,
											id: solution.id,
										})
									)
								}}
							>
								<LinkIcon />
							</IconButton>
						</Tooltip>
						{solution?.code || '-'}
					</TableCell>
				</TableRow>
				<AdminCreateSolution open={openModal} handleClose={handleClose} solutionId={solution.id} />
			</>
		)
	}
)

export default AdminSolutionsTableRow
