import { SecondaryBtn } from '@root-gipro/shared/Button/Button'
import React, { FC, memo, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Divider, Menu, MenuItem, Theme, Tooltip, createStyles } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { HelpOutline } from '@material-ui/icons'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import GetAppIcon from '@material-ui/icons/GetApp'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined'
import PublishIcon from '@material-ui/icons/Publish'
import RestorePageOutlinedIcon from '@material-ui/icons/RestorePageOutlined'
import { ModalContext } from '@root-gipro/context/modal-context'
import { appliedFilters } from '@root-gipro/modules/filter/store/actions'
import {
	createProjectTemplate,
	downloadCheckWarns,
	downloadForm20,
	downloadSvod,
	downloadTemplate,
	getProjects,
	importTechSolutions,
	massCheck,
	setIsNewProject,
	setLoadingStates,
	setOpenModalImportMagazine,
	setProjectUncs,
	updateCalc,
	uploadSvod,
} from '@root-gipro/modules/userProjects/store/actions'
import SortItems from '@root-gipro/shared/FilterHeader/components/sort-items'
import ProgressBar from '@root-gipro/shared/ProgressBar/ProgressBar'
import { ISortItem } from '@root-gipro/store/interfaces'
import { IState } from '@root-gipro/store/interfaces/state'
import { ROSSETI_ID } from '../../utils/helpers'
import { ImportDataMagazine } from '../Project/Modal'
import MassErrors from '../Project/Modal/MassErrors'
import SelectChoiceImport from '../Project/Modal/SelectChoiceImport'
import TooltipImport from './components/TooltipImport/TooltipImport'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		backdrop: {
			zIndex: theme.zIndex.drawer + 1,
			color: '#fff',
		},
		projectsHeadSortText: {
			color: 'rgba(0, 0, 0, 0.56)',
			fontSize: '14px',
			marginRight: 8,
		},
	})
)

export interface IUserProjectsNav {
	sort?(name: string, sortName: string, order?: boolean): void
	sortList: ISortItem[]
}

const UserProjectsNav: FC<IUserProjectsNav> = memo(({ sort, sortList }) => {
	const {
		userProjectsCnt,
		userProjects18Cnt,
		userProjects24Cnt,
		uncs,
		exportable,
		sectionIds,
		loadingStates,
		showLoader,
		dataImportMagazine,
		openModalImportMagazine,
		progressPrecent,
	} = useSelector((state: IState) => state.userProjects)

	const sectionIdsArr: number[] = sectionIds.map(item => Number(item.id))
	const [isOpenDownloadMenuExport, setIsOpenDownloadMenuExport] = useState<HTMLElement | null>(null)
	const [isOpenMenuTemplateExport, setIsOpenMenuTemplateExport] = useState<HTMLElement | null>(null)
	const [isOpenDownloadMenuImport, setIsOpenDownloadMenuImport] = useState<HTMLElement | null>(null)
	const [filtersInitialized, setFiltersInitialized] = useState(false)
	const [isOpenMassCheck, setIsOpenMassCheck] = useState<HTMLElement | null>(null)
	const { name, sortName, order } = useSelector((state: IState) => state.userProjects.sort)
	const { versionIpr } = useSelector((state: IState) => state.adminPanel)
	const [activeVersionIpr, setActiveVersionIpr] = useState<any>([])
	const companyArr = useSelector((state: IState) => state.public.company)
	const url: string = location.pathname.slice(1)
	const { filter } = useSelector((state: IState) => state)

	const currentFilter =
		url === 'common-projects'
			? filter.commonProjects
			: url === 'user-projects'
			? filter.userProjects
			: url === 'user-archive'
			? filter.userArchive
			: filter.userProjects

	const { resultFilterState, showFilterState } = useSelector((state: IState) => currentFilter)

	const [keyImport, setKeyImport] = useState<number>(0)
	const [typeImport, setTypeImport] = useState<string>('')

	const dispatch = useDispatch()
	const history = useHistory()
	const classes = useStyles()
	const { accessory, setAccessory, accessoryRef, setAnchorEl, anchorEl } = useContext(ModalContext)

	const roleId = Number(window.localStorage.getItem('roleId'))
	const companyId = Number(localStorage.getItem('companyId'))

	const importButtonDisabled = roleId <= 10 ? false : true
	const importButtonTitle = importButtonDisabled ? 'Импорт данных доступен только роли Администратор' : ''

	const startLoading = (itemKey: string) => {
		dispatch(setLoadingStates(itemKey, true))

		if (itemKey === 'export') {
			setIsOpenDownloadMenuExport(null)
		} else if (itemKey === 'check') {
			setIsOpenMassCheck(null)
		} else if (itemKey === 'import') {
			setIsOpenDownloadMenuImport(null)
		}
	}

	useEffect(() => {
		dispatch(getProjects())
		dispatch(setIsNewProject(false))
		if (uncs.length > 0) {
			dispatch(setProjectUncs([]))
		}
	}, [])

	const handlerCloseModal = () => {
		dispatch(setOpenModalImportMagazine(false))
	}

	useEffect(() => {
		if (showFilterState?.ipr_id && !filtersInitialized) {
			dispatch(appliedFilters(showFilterState))
			setFiltersInitialized(true)
		}
	}, [showFilterState?.ipr_id])

	useEffect(() => {
		setFiltersInitialized(false)
	}, [location.pathname])

	useEffect(() => {
		let filteredIPRs: any = ''

		if (versionIpr) {
			const company = companyArr.find((item: any) => item?.name === resultFilterState?.companyId)
			if (company && company.ipr) {
				filteredIPRs = company.ipr.filter((ipr: any) => versionIpr === ipr.version_name)
			}
		}
		if (filter.appliedFilters.ipr_id) {
			setActiveVersionIpr(filteredIPRs)
		} else {
			setActiveVersionIpr('')
		}
	}, [filter.appliedFilters])
	const [openHelpWindow, setOpenHelpWindow] = useState(false)
	const helpIconRef = useRef<SVGSVGElement | null>(null)
	const tooltipRef = useRef<HTMLDivElement | null>(null)
	const [closeTimeout, setCloseTimeout] = useState<NodeJS.Timeout | null>(null)

	const handleMouseEnter = () => {
		setOpenHelpWindow(true)

		if (closeTimeout) {
			clearTimeout(closeTimeout)
			setCloseTimeout(null)
		}
	}

	const handleMouseLeave = (event: React.MouseEvent) => {
		const relatedTarget = event.relatedTarget as Node | null

		if (
			helpIconRef.current &&
			tooltipRef.current &&
			(!relatedTarget ||
				!(relatedTarget instanceof Node) ||
				(!helpIconRef.current.contains(relatedTarget) && !tooltipRef.current.contains(relatedTarget)))
		) {
			const timeoutId = setTimeout(() => {
				setOpenHelpWindow(false)
			}, 500)

			setCloseTimeout(timeoutId)
		}
	}
	// внутри компонента:
	const fileInputRef = useRef<HTMLInputElement>(null)

	const handleFileSelectTechSolution = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0]
		if (file) {
			dispatch(importTechSolutions(file))
		}
	}

	return (
		<div className='user-projects__nav'>
			<div style={{ width: '100%' }}>
				<div className='user-projects__options'>
					<div className='user-projects__options-item'>
						<span className='user-projects__options-shrt-txt'>Найдено: </span>
						<span className='user-projects__options-count'>
							{userProjectsCnt}{' '}
							{userProjects24Cnt || userProjects18Cnt
								? `(${userProjects24Cnt} УНЦ-2024, ${userProjects18Cnt} УНЦ-2019)`
								: ''}
						</span>
					</div>
					{activeVersionIpr?.length ? (
						<div className='user-projects__options-item'>
							<span className='user-projects__options-shrt-txt'>Версия ИПР: </span>
							<span className='user-projects__options-count'>
								{activeVersionIpr
									?.map(
										(item: any) => `${item.version_name} - ${new Date(item?.created_at * 1000).toLocaleDateString()}`
									)
									.join(', ')}
							</span>
						</div>
					) : null}
				</div>
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
						{sort && <span className={classes.projectsHeadSortText}>Сортировка по:</span>}
						{sort && <SortItems sortItems={sort} sortList={sortList} />}
					</div>
					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
						<div>
							<SecondaryBtn
								className='add_btn'
								onClick={e => setIsOpenMenuTemplateExport(e.currentTarget)}
								disabled={loadingStates['export-template'] || showLoader}
							>
								<Tooltip title='Шаблоны'>
									<>
										{loadingStates['export-template'] ? <ProgressBar percent={progressPrecent} /> : null}
										Шаблоны
									</>
								</Tooltip>
							</SecondaryBtn>
							<>
								<Menu
									id='customized-menu'
									anchorEl={isOpenMenuTemplateExport}
									keepMounted
									open={Boolean(isOpenMenuTemplateExport)}
									onClose={() => setIsOpenMenuTemplateExport(null)}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'left',
									}}
									transformOrigin={{
										vertical: 'top',
										horizontal: 'left',
									}}
									PaperProps={{
										style: {
											border: '2px solid #d3d4d5',
											borderRadius: '4px',
											marginTop: '35px',
										},
									}}
								>
									<MenuItem
										onClick={() => {
											dispatch(downloadTemplate('f20OldTemplate'))
											startLoading('export-template')
										}}
									>
										Форма 20 (УНЦ-2019)
									</MenuItem>
									<Divider />
									<MenuItem
										onClick={() => {
											dispatch(downloadTemplate('f20Template'))
											startLoading('export-template')
										}}
									>
										Форма 20 (УНЦ-2024)
									</MenuItem>
									<Divider />
									<MenuItem
										onClick={() => {
											dispatch(downloadTemplate('f20NewTemplate'))
											startLoading('export-template')
										}}
									>
										Форма 20 (УНЦ-2024) обновленная
									</MenuItem>
									<Divider />

									<MenuItem
										onClick={() => {
											dispatch(downloadTemplate('f2Template'))
											startLoading('export-template')
										}}
									>
										Форма 2
									</MenuItem>

									<Divider />
								</Menu>
							</>
						</div>
						<div title={importButtonTitle}>
							<SecondaryBtn
								className='add_btn'
								disabled={importButtonDisabled || showLoader}
								onClick={e => {
									setIsOpenDownloadMenuImport(e.currentTarget)
								}}
							>
								<Tooltip title='Импорт'>
									<>
										{loadingStates['import'] ? (
											<ProgressBar percent={progressPrecent} />
										) : (
											<PublishIcon style={{ color: '#3f4f5e' }} fontSize='small' />
										)}
										Импорт
										<div
											className='content'
											onMouseEnter={handleMouseEnter} // Открытие окна подсказки
											onMouseLeave={handleMouseLeave}
										>
											<HelpOutline ref={helpIconRef} id='content__help_outline_img' />
										</div>
									</>
								</Tooltip>
							</SecondaryBtn>

							<div
								ref={tooltipRef}
								onMouseEnter={handleMouseEnter} // Открытие при наведении на TooltipImport
								onMouseLeave={handleMouseLeave} // Закрытие при уходе мышки
							>
								<TooltipImport
									open={openHelpWindow}
									ref={helpIconRef}
									setTypeImport={setTypeImport}
									setOpenHelpWindow={setOpenHelpWindow}
								/>
							</div>
							<>
								<Menu
									id='customized-menu'
									anchorEl={isOpenDownloadMenuImport}
									keepMounted
									open={Boolean(isOpenDownloadMenuImport)}
									onClose={() => setIsOpenDownloadMenuImport(null)}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'left',
									}}
									transformOrigin={{
										vertical: 'top',
										horizontal: 'left',
									}}
									PaperProps={{
										style: {
											border: '2px solid #d3d4d5',
											borderRadius: '4px',
										},
									}}
								>
									<label>
										<MenuItem>
											<span
												data-popup='import_form_modal'
												onClick={e => {
													setAnchorEl!(e.currentTarget)
													setTypeImport('import-form-2')
												}}
											>
												Импортировать данные из Формы 2
											</span>
										</MenuItem>
									</label>
									<Divider />

									{companyId === ROSSETI_ID ? (
										<label>
											<MenuItem>
												<input
													type='file'
													key={keyImport}
													accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
													onChange={e => {
														dispatch(uploadSvod(e.target.files))
														startLoading('import')
														setKeyImport(keyImport + 1)
													}}
													hidden
												/>
												Импортировать данные из Сводного файла
											</MenuItem>
										</label>
									) : null}
									<Divider />

									<label>
										<MenuItem>
											<span
												data-popup='import_form_modal'
												onClick={e => {
													setAnchorEl!(e.currentTarget)
													setTypeImport('import-form-20')
												}}
											>
												Импортировать данные из Формы 20
											</span>
										</MenuItem>
									</label>

									<Divider />
									<label>
										<MenuItem>
											<span
												data-popup='import_form_modal'
												onClick={e => {
													setAnchorEl!(e.currentTarget)
													setTypeImport('import-form-new-20')
												}}
											>
												Импортировать данные из Формы 20 (обновленная)
											</span>
										</MenuItem>
									</label>
									<>
										{/* Скрытый input[type=file] */}
										<input
											type='file'
											accept='.xlsx,.xls,.csv'
											ref={fileInputRef}
											style={{ display: 'none' }}
											onChange={handleFileSelectTechSolution}
										/>

										<MenuItem
											onClick={e => {
												fileInputRef.current?.click() // триггерим выбор файла
											}}
										>
											Импортировать технические решения
										</MenuItem>
									</>
									<Divider />
								</Menu>
							</>
						</div>
						{anchorEl?.dataset.popup === 'import_form_modal' && (
							<SelectChoiceImport
								anchorEl={anchorEl}
								data={''}
								setAnchorEl={setAnchorEl!}
								startLoading={startLoading}
								typeImport={typeImport}
							/>
						)}
						<div>
							<SecondaryBtn
								className='import_magazine'
								data-popup='import_magazine'
								onClick={e => dispatch(setOpenModalImportMagazine(true))}
								disabled={dataImportMagazine?.data?.length <= 0}
								title='Журнал импорта форм'
							>
								<Tooltip title='Журнал импорта форм'>
									<>
										<ImportExportIcon style={{ color: '#3f4f5e' }} fontSize='small' />
										Логи
									</>
								</Tooltip>
							</SecondaryBtn>
						</div>
						{openModalImportMagazine && (
							<ImportDataMagazine
								anchorEl={openModalImportMagazine}
								data={dataImportMagazine?.data}
								link={dataImportMagazine?.link}
								setAnchorEl={handlerCloseModal}
							/>
						)}
						<div>
							<SecondaryBtn
								className='add_btn'
								onClick={e => setIsOpenDownloadMenuExport(e.currentTarget)}
								disabled={loadingStates['export'] || showLoader}
							>
								<Tooltip title='Экспорт'>
									<>
										{loadingStates['export'] ? (
											<ProgressBar percent={progressPrecent} />
										) : (
											<GetAppIcon style={{ color: '#3f4f5e' }} fontSize='small' />
										)}
										Экспорт
									</>
								</Tooltip>
							</SecondaryBtn>
							<>
								<Menu
									id='customized-menu'
									anchorEl={isOpenDownloadMenuExport}
									keepMounted
									open={Boolean(isOpenDownloadMenuExport)}
									onClose={() => setIsOpenDownloadMenuExport(null)}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'left',
									}}
									transformOrigin={{
										vertical: 'top',
										horizontal: 'left',
									}}
									PaperProps={{
										style: {
											border: '2px solid #d3d4d5',
											borderRadius: '4px',
											marginTop: '35px',
										},
									}}
								>
									<MenuItem
										onClick={() => {
											dispatch(downloadForm20(null, null, 'all', 2018))
											startLoading('export')
										}}
									>
										Форма 20 (УНЦ-2019)
									</MenuItem>
									<Divider />
									<MenuItem
										onClick={() => {
											dispatch(downloadForm20(null, null, 'all', 2024))
											startLoading('export')
										}}
									>
										Форма 20 (УНЦ-2024)
									</MenuItem>
									<Divider />
									<MenuItem
										onClick={() => {
											dispatch(downloadForm20(null, null, 'allNewPlus', 2024))
											startLoading('export')
										}}
									>
										Форма 20 (УНЦ-2024) обновленная
									</MenuItem>
									{/* {exportable && (
										<>
											<Divider />
											<MenuItem
												onClick={() => {
													dispatch(downloadForm2(null, null))
													startLoading('export')
												}}
											>
												Форма 2
											</MenuItem>
										</>
									)} */}
									{companyId === ROSSETI_ID ? (
										<>
											<Divider />
											<MenuItem
												onClick={() => {
													dispatch(downloadSvod(null, null))
													startLoading('export')
												}}
											>
												Сводная форма
											</MenuItem>
										</>
									) : null}
								</Menu>
							</>
						</div>
						<SecondaryBtn
							className='add_btn'
							onClick={() => {
								dispatch(createProjectTemplate())
								history.push('user-projects/project/0/parameters')
							}}
						>
							<NoteAddOutlinedIcon /> Создать
						</SecondaryBtn>

						<SecondaryBtn
							className='add_btn'
							onClick={e => setIsOpenMassCheck(e.currentTarget)}
							disabled={loadingStates['check'] || showLoader}
						>
							<>
								{loadingStates['check'] ? <ProgressBar percent={progressPrecent} /> : <DescriptionOutlinedIcon />}
								Проверить
							</>
						</SecondaryBtn>
						<Menu
							id='customized-menu'
							anchorEl={isOpenMassCheck}
							keepMounted
							open={Boolean(isOpenMassCheck)}
							onClose={() => setIsOpenMassCheck(null)}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}
							PaperProps={{
								style: {
									border: '2px solid #d3d4d5',
									borderRadius: '4px',
									marginTop: '35px',
								},
							}}
						>
							<MenuItem
								data-popup='errors-list'
								onClick={e => {
									dispatch(massCheck(sectionIdsArr))
									setAnchorEl!(e.currentTarget)
									setAccessory!('errors-list')
									if (accessoryRef) accessoryRef.current = accessory
									setIsOpenMassCheck(null)
								}}
								className='add_btn'
							>
								Отчет он-лайн
							</MenuItem>
							<Divider />
							<MenuItem
								onClick={e => {
									dispatch(downloadCheckWarns(sectionIdsArr))
									setIsOpenMassCheck(null)
									startLoading('check')
								}}
								className='add_btn'
							>
								Отчет в файл
							</MenuItem>
						</Menu>
						<SecondaryBtn
							className='add_btn'
							data-popup='errors-list'
							onClick={e => {
								dispatch(updateCalc({ name, sortName, order }))
								startLoading('recalculate')
							}}
							disabled={loadingStates['recalculate'] || showLoader}
						>
							{loadingStates['recalculate'] ? <ProgressBar percent={progressPrecent} /> : <RestorePageOutlinedIcon />}
							Пересчитать
						</SecondaryBtn>
						{anchorEl?.dataset.popup === 'errors-list' && (
							<MassErrors
								setAnchorEl={setAnchorEl!}
								anchorEl={anchorEl}
								sectionIds={sectionIds}
								sectionIdsArr={sectionIdsArr}
								setAccessory={setAccessory}
								accessory={accessory}
								accessoryRef={accessoryRef}
								handler={() => {
									setAccessory!('error-list')
									if (accessoryRef) accessoryRef.current = accessory
								}}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	)
})

export { UserProjectsNav }
