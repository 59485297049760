import {
	Backdrop,
	CircularProgress,
	createStyles,
	IconButton,
	makeStyles,
	Modal,
	TextField,
	Theme,
} from '@material-ui/core'
import { Add } from '@material-ui/icons'
import GetAppIcon from '@material-ui/icons/GetApp'
import style from '@root-gipro/modules/AdminPanel/styles/adminPanel.module.scss'
import Directions from '@root-gipro/modules/userProjects/components/Project/UncAdd/Directions'
import { SelectTable } from '@root-gipro/modules/userProjects/components/Project/UncAdd/SelectTable'
import { UncList } from '@root-gipro/modules/userProjects/components/Project/UncAdd/UncList'
import { getUncCells, selectTableElem, setLoadingStates } from '@root-gipro/modules/userProjects/store/actions'
import { SecondaryBtn } from '@root-gipro/shared/Button/Button'
import ProgressBar from '@root-gipro/shared/ProgressBar/ProgressBar'
import { fetchPublicUserProjectInfo } from '@root-gipro/store/actions'
import { IState } from '@root-gipro/store/interfaces'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	downloadSolutions,
	setAdminUncsModalState,
	setIsDuplicateState,
	setSolutionObj,
	setSolutionsParams,
} from '../../store/actions'
import AdminCreateSolution from './components/AdminCreateSolution/AdminCreateSolution'
import AdminSolutionsTable from './components/AdminSolutionsTable/AdminSolutionsTable'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			borderRadius: 8,
		},
		textField: {
			'& .MuiInputBase-root': {
				width: '300px',
			},
		},
	})
)

const AdminSolutions = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const {
		solutionsTech,
		loadSolutions,
		loadingSolutionsPage,
		solutionsTechParams,
		isAdminUncsModalState,
		createLoad,
		hasDuplicates,
		editLoadingId,
	} = useSelector((state: IState) => state.adminPanel)
	const { uncTableData, loadingStates, progressPrecent } = useSelector((state: IState) => state.userProjects)
	const { uncMains } = useSelector((state: IState) => state.public)

	const companyId = localStorage.getItem('companyId') ?? undefined

	const [sort, setSort] = useState<string>('id DESC')
	const [filterName, setFilterName] = useState<string>('')
	const [open, setOpen] = useState<boolean>(false)
	const [showDirections, setShowDirections] = useState<boolean>(false)
	const [isDuplicate, setIsDuplicate] = useState(0)

	const memoizedSolutions = useMemo(() => solutionsTech.solutions, [solutionsTech.solutions])

	// useRef для хранения предыдущего значения фильтра
	const prevFilterRef = useRef(filterName)
	useEffect(() => {
		if (!uncMains.length) {
			dispatch(getUncCells())
			dispatch(fetchPublicUserProjectInfo('2024'))
		}
	}, [])

	useEffect(() => {
		// if (solutionsTechParams?.page === undefined || solutionsTech.solutions.length === 0) {
		companyId && dispatch(setSolutionsParams({ page: 0, order: sort, companyId: companyId }))
		// }
	}, [])

	const handleOpen = () => {
		setOpen(true)
		dispatch(setSolutionObj(null))
	}
	const handleClose = () => setOpen(false)

	const handleCloseModalUncs = () => {
		dispatch(setAdminUncsModalState({ linkOpen: false, editOpen: false }))
		dispatch(selectTableElem(null))
	}

	const loadMoreSolutions = () => {
		if (loadSolutions || solutionsTech?.resultsCnt <= solutionsTech?.solutions.length) return
		const nextPage = (solutionsTechParams?.page ?? 0) + 1
		dispatch(setSolutionsParams({ page: nextPage }))
	}

	const handleBlur = () => {
		if (prevFilterRef.current !== filterName) {
			dispatch(setSolutionsParams({ page: 0, techParamName: filterName }))
			prevFilterRef.current = filterName
		}
	}

	const repeatUncs = useCallback(() => {
		const newIsDuplicate = solutionsTechParams.is_duplicate === 0 ? 1 : 0
		dispatch(setSolutionsParams({ page: 0, is_duplicate: newIsDuplicate }))
	}, [solutionsTechParams.is_duplicate])

	const onChangeSort = (newSort: string) => {
		setSort(newSort)
		dispatch(setSolutionsParams({ page: 0, order: newSort }))
	}

	// локальная проверка массива на ключ дубликата
	const checkHasDuplicates = (solutions?: { is_duplicate?: number }[]): boolean => {
		if (!solutions || solutions.length === 0) return false
		return solutions.some(item => item.is_duplicate === 1)
	}

	const checkDuplicates = useMemo(() => {
		return checkHasDuplicates(solutionsTech?.solutions)
	}, [editLoadingId, solutionsTech?.solutions])

	// если в подгруженных данных есть элемент с дубликатом или с сервера ключ дубликатов приходит заполненным тогда подсвечиваем
	const buttonClassName =
		(hasDuplicates || checkDuplicates) && solutionsTechParams.is_duplicate === 0
			? style.add_btn__replays
			: style.add_btn

	useEffect(() => {
		const duplicates = checkHasDuplicates(solutionsTech?.solutions)
		dispatch(setIsDuplicateState(duplicates ? 1 : 0))
	}, [solutionsTech?.solutions])

	return (
		<div className='adminPanel-container'>
			<div className={style.adminPanel__header}>
				<div className={style.adminPanel__headerLeft}>
					<IconButton onClick={handleOpen} classes={classes} disabled={createLoad}>
						{createLoad ? <CircularProgress size={20} /> : <Add />}
					</IconButton>
					<TextField
						className={classes.textField}
						variant='outlined'
						placeholder='Техническое решение или УНЦ'
						value={filterName}
						onChange={e => setFilterName(e.target.value)}
						onBlur={handleBlur}
					/>
				</div>
				<div className={style.adminPanel__header__export_container}>
					<p>
						Всего технических решений: <span className={style.boldText}>{solutionsTech?.resultsCnt ?? 0}</span>
					</p>
					<SecondaryBtn
						className={style.add_btn}
						onClick={() => {
							dispatch(downloadSolutions())
							dispatch(setLoadingStates('downloadSolution', true))
						}}
					>
						{loadingStates['downloadSolution'] ? (
							<ProgressBar percent={progressPrecent} style={{ width: '20px' }} />
						) : (
							<GetAppIcon style={{ color: '#3f4f5e' }} fontSize='small' />
						)}
						Экспорт
					</SecondaryBtn>
					<SecondaryBtn className={buttonClassName} onClick={() => repeatUncs()}>
						{solutionsTechParams.is_duplicate === 1 ? 'Общий список' : 'Повторы'}
					</SecondaryBtn>
				</div>
			</div>

			{/* Таблица решений */}
			<AdminSolutionsTable
				solution={memoizedSolutions}
				loadMore={loadMoreSolutions}
				loadSolutions={loadSolutions}
				loadingSolutionsPage={loadingSolutionsPage}
				sort={sort}
				onChangeSort={onChangeSort}
			/>

			{/* Модальное окно добавления решения */}
			<AdminCreateSolution open={open} handleClose={handleClose} />

			{/* Модальное окно для выбора УНЦ */}
			<Modal
				className='modal_admin_uncs'
				aria-labelledby='transition-modal-title'
				aria-describedby='transition-modal-description'
				open={isAdminUncsModalState.editOpen || isAdminUncsModalState.linkOpen}
				onClose={handleCloseModalUncs}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<div className='scroll-wrapper'>
					<Directions
						showDirections={showDirections}
						text={uncTableData?.note || ''}
						closeHandler={() => setShowDirections(false)}
					/>
					<div className='unc_add unc_add_container_admin' style={{ filter: showDirections ? 'blur(4px)' : '' }}>
						<UncList solutionCase={true} />
						<div className='wrapper-select-table admin_side_open'>
							<SelectTable setShowDirections={setShowDirections} solutionCase={true} />
						</div>
					</div>
				</div>
			</Modal>
		</div>
	)
}

export default AdminSolutions
